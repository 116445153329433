@import "../../assets/css/_variables";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;900&display=swap');

.sign-up-wrapper .error-message {
  font-family: 'Montserrat', sans-serif !important;
  color: red !important;
  font-weight: 400 !important;
}

.sign-up-wrapper h1, .sign-up h1 {
  font-family: 'Montserrat', sans-serif !important;
  color: white !important;
  font-weight: 900 !important;
  border-bottom: unset !important;
  text-align: left !important;
  font-size: 64px !important;
  padding-bottom: 0.5em !important;
}

.sign-up-wrapper h3, .sign-up h3 {
  font-family: 'Montserrat', sans-serif !important;
  color: white !important;
  font-weight: 400 !important;
  border-bottom: unset !important;
  text-align: left !important;
  font-size: 18px !important;
  line-height: 1.6;
}

// ----- revamp styles
.sign-up-wrapper {
  height: unset !important;
  color: white;
  display: block;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 100vh;

  @include desktop {
    display: flex;
  }

  * {
    box-sizing: border-box;
  }

  label, input, p, select {
    font-size: 1em;
  }

  .error-message {
    margin: 0.5em 0;
    display: block;
  }

  // Reset
  .inperror {
    border: none !important;
  }

  .sign-up-box {
    flex-basis: 100%;
    padding: 4rem 4rem 0;

  @include desktop {
    &:nth-of-type(1) {
      flex-basis: calc(40% - 8rem);
    }

    &:nth-of-type(2) {
      flex-basis: calc(60% - 8rem);
      flex-grow: 1;
    } 
  }

  .sign-up-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      .input-field {
        flex-basis: calc(100%);
        margin: 1em 0 1em 0;
        flex-basis: 100%;

        @include tablet {
          flex-basis: 50%;

          &:nth-of-type(odd) {
            flex-basis: calc(50% - 2em);
            margin: 1em 2em 1em 0;
          }
        }

        label {
          margin: 0.5em 0;
        }

        input {
          border-bottom: 0.1em solid white;
          color: white;
          padding: 0.2em;
        }

        select {
          background: white;
          width: 100%;
          padding: 0.2em;

          option {
            padding: 0.2em;
          }
        }

        ul {
          display: flex;

          li {
            margin: 0.5em 1em 0.5em 0;
            display: flex;

            input {
              margin-right: 0.5em;
            }
          }
        }
      }

      &.two {
        @include tablet {
          flex-basis: 50% !important;
        }

        .two_panel {
          flex-basis: 100%;

          @include tablet {
            flex-basis: 50%;
          }

          p {
            margin: 0.5em 1em 0 0;
          }
        }
       
      }

      &.six {
        flex-direction: column;
        align-items: flex-start;

        button {
          margin: 1em 0;
        }

        input {
          margin: 0 0 2em 0;
        }
      }
  }
  } 
}