@import '../../../css/variables.scss';

.dialogue-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .dialogue-box_background {
        background: $modal-bg-white;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .dialogue-box_content {
        min-width: 350px;
        background: white;
        border-radius: 0.25rem;
        padding: 3rem 2rem 2rem 2rem;
        margin: 2rem;
        position: relative;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.39);
    }

    p {
        line-height: 1.2;
    }

    .close {
        position: absolute;
        border-radius: 0.25rem;
        top: 0;
        right: 0;
        padding: 1rem;
        margin: 0.75rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-self: center;
        align-items: center;

        &:hover {
            background-color: #F1F1F1;
        }
    }
}