    .login-form-rf {
        a {
            padding: 0 1rem;
            display: inline-block;
        }

        aside {
            p {
                font-size: 1.2em !important;
                font-weight: bold;
                color: #1B294D;
            }
        }
    }
