// .__react_component_tooltip {
//     display: none !important;
// }

.toggle-switch {
    margin: 0 0.5rem 0 1rem;
    width: 3.5rem;
    height: 1.5rem;
    border: 2px solid #0D224B;
    border-radius: 0.25rem;
    overflow: hidden;
    position: relative;
   

    .toggle-switch_icon {
        background: #0D224B;
        color: white;
        height: 1.25rem;
        width: 2.33rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;


        svg {
            width: 0.5rem;
            position: relative;
        }
    }
}