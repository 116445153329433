@import "../../../../../../css/variables.scss";

.deal-details-new {
    width: 100%;

    .deal-details-static {

        // @include desktop-xl() {
        //     display: flex;
        // }

    .deal-details_panel {
        flex-basis: 100%;


        // @include desktop-xl() {

        //     &.left {
        //         flex-basis: calc(50% - 1rem);
        //         margin-right: 1rem;
        //     }

        //     &.right {
        //         flex-basis: calc(50% + 1rem);
        //     }
        // }

        table.table {
        display: block;
        border-radius: 0.25rem;

        thead {
            width: 100%;
            display: flex;
            justify-content: flex-start;

           tr {
            background: #F1F1F1;
            border-top: 0.1em solid #F1F1F1;
            width: 100%;

            th {
               span {
                font-weight: bold;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
               }
            }
           }
        }
        
        tbody {
            width: 100%;
            display: block;
            text-align: left;

            tr {
                display: flex;
                width: 100%;
                border-bottom: 0.1em solid #F1F1F1;
    
                th,td {
                    flex-basis: 50%;
                    overflow: hidden;
                    white-space: nowrap;
                    position: relative;

                   
                    &:after {
                        content: '';
                        height: 100%;
                        width: 0.5rem;
                        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                   
                    span {
                        // padding: 0.25em 1rem !important;
                        width: 100%;
                        display: block;

                    }
                }

                th {
                    border-right: 0.1em solid #F1F1F1;
                }
            }
        }
    }

  

    &.left {
        margin-bottom: 1rem;
    }

    &.right {
       table.table {
           tr {
               th {
                   flex-basis: 100%;

                   @include phone-med() {
                    flex-basis: 50%;
               }

                //    @include desktop-xl() {
                //         flex-basis: 85%;
                //    }

                   .date {
                       display: inline;
                       font-weight: lighter !important;
                       text-transform: none;
                       font-style: italic;
                       padding: 0 0;
                   }
               }
           }
       }

    }
    }
}
}