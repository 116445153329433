.button-submit-rf {
    background-color: #0086CA;
    color: white;
    padding: 1.5rem 2rem;
    border-radius: 0.15rem;
    position: relative;
    display: block;
    opacity: 1;
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: max-content;
    width: auto;
    min-width: 20rem;
    margin: 2rem auto 2rem auto;
    border: 0.25rem solid #0092d000;

    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: 0.25rem solid #0091d0;
        outline-offset: -0.25rem;
        // background-color: #0091d0;
        transition: unset;
    }

    &::after {
        content: '';
        background-color: #0000000e;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.15rem;
        z-index: -1;
    }

    &:disabled  {
        background-color: #0000000e;
        pointer-events: none;
    }

    .spinner {
        margin: 0 0 0 1rem;
        display: none;
        padding: 0;
    }

    &.read-only {
        background-color: #EFEFEF !important;
        color: #0C224B !important;
        pointer-events: none !important;

        .spinner {
           display: flex;
        }

        &:hover {
            opacity: 1;
        }
    }

   // optional variants (passed via variant prop)
   &.rounded {
       border-radius: 2rem;
   }
}