button.link-expandable {
        border-radius: 0 !important;
    
        span {
            pointer-events: none;
        }
    
        &.expanded {
                border-left: 0.5em solid #0091D0 !important;
                color: #0091D0 !important;

                .button-icon {
                    color: #0091D0 !important;
                }

            span {
                &:nth-of-type(3) {
                    transform: rotate(180deg);
                }
            }
        }
    
}