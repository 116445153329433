.button-submit {
    background-color: #0086CA;
    color: white;
    padding: 1rem 2rem;
    border-radius: 0.15rem !important;
    position: relative;
    display: block;
    opacity: 1;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: 0.15rem solid #0091d0 !important;
        background-color: #0091d0;
        transition: unset !important;
    }

    &::after {
        content: '';
        background-color: #0000000e;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.15rem !important;
        z-index: -1;
    }

    &:disabled  {
        background-color: #0000000e;
    }

    .spinner {
        margin: 0 0 0 1rem;
        display: none;
        padding: 0;
    }

    &.read-only {
        background-color: #EFEFEF !important;
        color: #0C224B !important;
        pointer-events: none !important;

        .spinner {
           display: flex !important;
        }

        &:hover {
            opacity: 1;
        }
    }

   
}