#loss-mitigation-form {
    textarea {
        width: 100%;
        background-color: #f1f1f1;
        padding: 1rem;

        &:focus {
            outline: 3px solid #0091D0 !important;
            outline-offset: -3px;
        }
    }
}