@import "_reset";
@import "_variables";

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
  height: 100%;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  scroll-behavior: auto;
  * {
    border-radius: 0rem;
  }
}

html {
  scroll-behavior: smooth;
}

button {
  outline: none;
}

a, button {
  cursor: pointer !important;
}

.btn-submit {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: .8125rem;
  padding: 1.4rem 1rem;
  background-color: #0091d0;
  cursor: pointer;
  border: none;
  display: block;
  width: auto;
  margin: 2em 0;  
  border: 0.15em solid #0091d0 !important;

  &:focus {
      outline: 0.15rem solid #0091d0 !important;
      border: 0.15em solid #0091d0 !important;
      background-color: #0091d0 !important;
  }

  &.green {
    background-color: #00804B;
    border: 0.15em solid #00804B !important;

    &:focus {
      outline: 0.15rem solid #0091d0 !important;
      background-color: #00804B;
      border: 0.15em solid #00804B !important;
  }
  }
}

h1 {
  font-weight: 600;
  font-size: 1.75rem;
}

.inperror {
  border: 1px solid $orange !important;
}

.laberror {
  color: $orange !important;
}

.selerror {
  border: 1px solid $orange !important;
  color: $orange !important;
}

.erro-mess {
  padding-top: .5rem;
  padding-left: .5rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: $orange;
  display: block;
}

.container {
  display: grid;
  grid-template-columns: 20.3125rem 10fr;

  @media (max-width: 800px) {
    display: block;
  }
}

.title {
  border-bottom: 2px solid $black;
  padding-bottom: .9rem;
}

.hide {
  display: none;
}

;

.floating {
  margin-bottom: .5rem;
  background-color: var(--field__background);
  transition: background-color 0.2s ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.floating:hover,
.floating:focus-within {
  background-color: var(--field__background--hover);
}

.floating__input {
  padding: 1.8rem 1rem 0.6rem;
  font-size: 1rem;
  border-bottom: 0.1rem solid var(--input__border);
  transition: border-color 0.2s ease;
  caret-color: var(--color__accent);
}

.floating:hover .react-datepicker-wrapper .react-datepicker__input-container .floating__input {
  border-color: var(--input__border--hover);
}

.floating:hover .floating__input {
  border-color: var(--input__border--hover);
}

.floating__input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__label {
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
  color: $darker-grey;
}

.floating__label::before {
  color: var(--label__color);
  content: attr(data-content);
  display: inline-block;
  filter: blur(0);
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 1rem;
  position: relative;
}

.floating__label::after {
  bottom: 1rem;
  content: "";
  height: 0.1rem;
  position: absolute;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
  opacity: 0;
  left: 0;
  top: 100%;
  margin-top: -0.1rem;
  transform: scale3d(0, 1, 1);
  width: 100%;
  background-color: var(--color__accent);
}

.floating__input:focus+.floating__label::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.floating__input:placeholder-shown+.floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__label::before,
.floating__input:focus+.floating__label::before {
  transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
}


.floating__input:focus+.floating__label::before {
  color: var(--color__accent);
}

.date .floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.date:focus-within .floating__label::before,
.floating__label.touched::before {
  transform: translate3d(0, -3.12rem, 0) scale3d(.82, .82, 1);
}

.hidden--visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  color: $darker-grey;
}

input {
  background: none;
  border-width: 0;
  display: block;
  width: 100%;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  outline: none;

  >div {
    max-height: 70vh;
    padding: 2rem;
    overflow: auto;

  }
}

/* Left Side Bar */

.left-side-bar {
  @media (min-width: 800px) {
    background-color: $dark_blue;
    padding-top: 3.125rem;
    text-align: center;
    color: white;
    position: relative;
    min-height: 100vh;
    padding-bottom: 300px;
    box-sizing: border-box;
  }
}

/* Right Side */
.right-side {
  height: 100%;
  width: 80vw;

  @media (max-width: 800px) {
    float: none;
    display: block;
    width: 100%;
    flex: 100%;
  }

  a {
    color: $lighter___blue;
    text-decoration: none;
    transition: text-shadow .3s;
  }

  a:hover {
    color: #0c224B;
  }

  .inner-section {
    padding: 3rem 5rem;

    @media (max-width: 425px) {
      padding: 2rem;
    }
  }

  .search-header {
    display: grid;
    grid-template-columns: 6fr 6fr;

    .search-header-left {
      padding-bottom: 0.5rem;

      li a {
        margin-top: 0.25em;
        display: inline-block;
      }
    }

    .search-header-right {
      text-align: right;
      font-size: 0.9375rem;
      padding-bottom: 0;

      ul {
        li {
          display: inline-block;

          .bolder {
            font-weight: 600;
          }

          .search-peice {
            padding-right: 1rem;
            vertical-align: middle;
          }

          input[type="text"] {
            width: 12.1875rem;
            height: 2rem;
          }
        }
      }
    }
  }
}

.lip-inner {
  padding: 3rem 5rem;

  @media (max-width: 1200px) {
    padding: 4rem 2rem 2rem 2rem;
  }

  .search-header {
    border-bottom: 2px solid $black;
  }

  .property-box {
    .property-box-title {
      display: grid;
      grid-template-columns: 6fr 4fr 2fr;
    }
  }
}

@media (max-width: 800px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 800px) {
  .desktop-hide {
    display: none;
  }
}