@import '../../../../../../../../../css/variables.scss';

.new-beneficary {
    position: relative;

    // .modal-close {
    //     position: absolute;
    //     right: 1rem;
    //     top: 1rem;
    //     font-size: 2rem;
    //     cursor: pointer;
    // }

    .button-group {
        display: flex;
        
        &:first-of-type {
            margin: 0 1rem 0 0;
        }

        button {
            margin-right: 1rem;
            min-width: 8rem;
        }
    }

    .input-field-group_inner {
        flex-basis: 100% !important;
    }

    .beneficiary-error {
        color: rgb(212, 49, 30);
    }
}