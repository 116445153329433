.rep-contact-info {
    span,a {
        // margin-left: 1rem;
        color: white !important;
        line-height: 1.2;
    }

}

.thank-you-rf {
    a {
        color: white !important;
    }

    p {
        margin-left: 0 !important;
    }
}