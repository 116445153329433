@import "../../../../assets/css/_variables";

.profile-form {
 
  .error {
    padding: 1rem;
    font-weight: bold;
  }
}


.profile-form {

  .profile-form-bottom  {
    display: block !important;

    .input-field {
      display: block;

      input {
        color: black;
        display: block;
        order: 1;
        height: 3.5rem !important;
      }

      label {  
        span {
          display: block;
          margin-top: 1.5rem;
          position: relative;
          height: unset;
          width: unset;
          color: black;
        }
      }
    }
}


}

#profile-btn .spinner {
  display: none;
}