@import "./css/variables.scss"; // Global Variables/Mixins/Media Queries
@import "./css/tooltips.scss"; // Global ToolTips
@import "./css/tables.scss"; // Global Table Base Styles
@import "./css/skeleton.scss"; // Global Loader Skeleton Styles
@import "./css/app-new.scss"; // Global App Refactor/Design Audit Styles (break out as needed for future refactor)
@import "./css/forms.scss"; // Global Form Styles (including input field grids)
@import "../assets/css/forms"; // Legacy Form Styles (unclear if this is still needed (originally imported way below - uncomment if anything breaks - JR 2021-12-01)
@import "./css/grid.scss";

// ----- v General Document Formatting v ---------------------------------------------------------------------------------
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.terms-list {
  list-style-type: lower-alpha !important;
  padding: 0 !important;
  margin-left: 3rem;
}

.terms-list li {
  padding: 0.3rem 0 !important;
}

.terms-list ol {
  margin-left: 2rem !important;
}

// ----- v Misc. Ad-Hoc, Legacy, and Shim Formatting v -------------------------------------------------------------------
.app {
  height: 100vh;
  background: white;
  color: #10295b;
  flex-direction: column;
}

#root {
  height: 100vh;
}

main {
  width: 100%;
}

label {
  text-align: left;
  width: 100%;
  margin-bottom: 0.25em !important;
  display: block;
  padding: 0.2rem !important;
}

.login-form-new input {
  color: white;
  margin-bottom: 0.25em !important;
  border: 0.01rem solid white31 !important;
  padding: 0.2rem !important;
}

input[type="submit"] {
  // background-color: #EF3E36;
  color: #10295b;
  text-transform: uppercase;
  margin: 0.5rem 0 !important;
  font-weight: bold;
  padding: 0.5rem 1rem !important;
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
  min-width: 6rem;
}

h1,
h2,
h3 {
  color: #10295b;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 1rem !important;
}

h1 {
  padding-bottom: 0.5rem !important;
  margin-bottom: 1rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  &.heading-main {
    border-bottom: none !important;
  }
}

.heading-main {
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: 4rem !important;

  &.text-white {
    color: white;
  }
}

.form.loc-borrower {
  color: white;
  padding: unset !important;

  p {
    color: #1c3144 !important;
  }

  h1 {
    color: #1c3144 !important;
    border-color: #1c3144 !important;
  }



}

// Temp fixes
.select-application.borrow-app,
.sign-up,
.thank-you,
.sign-up.four-oh-four,
.profile-form {
  color: white;

  h1,
  h2,
  p,
  .sign-up-title,
  .section-heading,
  input,
  label {
    border-color: white !important;
    color: white;
  }
}

// states checkbox list hot fixes
.input-field-group_inner.states-checkbox {
  .input-field.full-width {
    padding-right: 0 !important;
  }

  ul {
    display: block;
    padding-top: 0rem;

    li {
      margin: 0 !important;
      overflow: auto;
      height: unset !important;

      input {
        height: unset !important;
      }

      label {
        margin: 0 !important;
        padding-top: 0 !important;
        cursor: pointer;
      }
    }
  }
}

// @import "../assets/css/forms";

.form-submit {
  margin-left: -1.6rem !important;
}

.terms-conditions-wrapper {
  .terms-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    background-color: #000000d2;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s ease;
    z-index: 999999;

    &.is-active {
      opacity: 1;
      pointer-events: all;
    }

    .terms-inner {
      background-color: #fff;
      max-width: 900px;
      max-height: 70%;
      width: 70%;
      overflow: scroll;
      padding: 6rem 3rem 6rem 3rem !important;
      position: relative !important;
      text-align: justify;

      @include tablet {
        width: 60%;
        height: auto;
      }

      .close {
        display: block;
        position: absolute !important;
        top: 0;
        right: 0;
        width: 100%;
        // text-align: right;

        .terms-close {
          font-size: 2rem;
          display: block;
          padding: 3rem;
          position: absolute;
          right: 0;
          color: black !important;
        }
      }

      .terms-inner_body {
        pointer-events: none;

        h1 {
          border-bottom: 2px solid #000000;
          padding-bottom: 0.9rem;
        }

        p {
          padding: 0.5rem 0;
          font-size: 0.8125rem;
        }
      }
    }
  }
}

.select-borrower-customer {
  padding-top: unset !important;
  margin-top: -1rem !important;
  margin-left: 0.2rem !important;
}

.input-radio-fields {
  .input-field {
    border: unset !important;
  }

  tr {
    border-bottom: 0.01rem solid lightgray;
    display: flex;

    td {
      flex-basis: 50%;
    }

    td:first-child {
      border-right: 0.01rem solid lightgray;
    }
  }

  tr:last-child {
    border-bottom: unset !important;
  }
}

.hidden-input-field {
  .message {
    padding: 1rem;
    color: #d4311e;
    display: block;

    &.is-hidden {
      display: none;
    }
  }

  .hidden-input {
    display: block;

    &.is-hidden {
      display: none;
    }

    .hidden-input_inner {
      &.error {
        label {
          color: #d4311e;
        }
        input {
          background: #f7d8d3;
          outline: 0.1em solid #d4311e;
          color: black;
        }

        .error-message {
          opacity: 1 !important;
          padding: 1rem;
        }
      }
    }
  }
}

.borrowing-entities {
  p {
    margin-top: 1rem !important;
  }
}

.borrowing-entities .col-left {
  flex-basis: 50% !important;
  margin-bottom: 5rem !important;
}

.borrowing-entities .col-right {
  flex-basis: 40% !important;
  margin-right: 2rem !important;
  padding-bottom: 1rem;
}

.select-borrower {
  display: flex !important;
  flex-wrap: wrap !important;

  select {
    flex-basis: 50% !important;
  }

  label {
    flex-basis: 100% !important;
  }
}

.areas-of-operation {
  ul {
    justify-content: space-between !important;
    flex-basis: 100% !important;
    padding-top: 1rem !important;
    flex-wrap: wrap;

    @media screen and (max-width: 750px) {
      li {
        flex-basis: 45% !important;
      }
    }

    input {
      width: 22px;
      height: 22px;
    }
  }
}

.select-customer {
  display: flex !important;
  flex-wrap: wrap !important;

  select {
    flex-basis: 50% !important;
  }

  label {
    flex-basis: 100% !important;
  }
}

.states-checkbox {
  ul {
    padding-top: unset !important;
    overflow-y: scroll !important;
    display: block !important;

    li {
      margin: 0rem 0.5rem 0rem 0.5rem !important;
      height: 2.5rem !important;

      input {
        height: 2.5rem !important;
      }
    }
  }

  .input-field {
    border: 0.15rem solid #f1f1f1;
  }
}

.input-field {
  ul {
    padding-top: unset !important;
  }
}

.hidden-input {
  .input-field {
    border: unset !important;
  }
}

.hidden-input:last-child {
  padding-bottom: 1rem !important;
}

// bug fix for applications overlap as seen in LOC
.section {
  .row {
    display: block;
  }
}

.section.consent {
  hr {
    margin: 1rem 0 !important;
  }

  .section_heading {
    padding: 1rem 0;

    h1,
    h2 {
      padding-left: 0 !important;
      margin: 0;
    }
  }
  .input-field-group {
    &.file-upload-group {
      .input-field {
        &.file-upload {
          padding: 0 !important;
          // background: red;

          p.red {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }

    .btn-submit {
      &.file-upload {
        color: white !important;
        background: unset;
        border: 0.15rem solid #0091d0;
        background-color: #0091d0;
        display: flex;
        align-items: center;
        width: auto;
        font-size: 13px !important;
        max-width: max-content;
        height: 3.5rem;
        padding: 1.2rem 1rem;
        margin: 1rem 0;
      }
    }
  }
}

.select-form-type ul {
  padding-top: unset !important;
}

.form-submit {
  ul {
    padding-top: unset !important;
  }
}

#agree-to-terms {
  width: 1.5rem;
  height: 1.5rem !important;
}

.borrowing-entities {
  .row {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }

  .column {
    flex-basis: 48% !important;
  }
}

.app-protected_side::-webkit-scrollbar {
  background-color: #10295b;
  width: 0.1rem;
}

.app-protected_side::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #10295b;
}

.app-protected_side::-webkit-scrollbar-thumb {
  background-color: #10295b;
  outline: 1px solid $dark_blue;
  // border-top-left-radius: 0.25rem;
  // border-bottom-left-radius: 0.25rem;
}

.app-protected_side::-webkit-scrollbar-button {
  display: none !important;
}

.additional-guarantor-form {
  h2 {
    font-size: 1.5rem !important;
  }

  p {
    padding-left: unset !important;
  }

}

.layout_white-label-applications .inner .form.loc-borrower.additional-guarantor-form {
  h1, h2, h3, h4, h5, label, span, small, p {
    color: #0c224b !important;
  }
}

.please-fill {
  margin-left: -0rem !important;
}

.consent.section {
  h1 {
    margin-left: 1rem !important;
    width: 97% !important;
  }

  .row {
    justify-content: flex-start !important;
    .column {
      flex-basis: 33% !important;
      text-align: left;
    }

    .file-upload {
      height: unset !important;
      padding: 1.3rem 2.25rem !important;
      margin: -0rem !important;
      margin-top: 0.1rem !important;
    }
  }
}

.input-field-group.boxed {
  table {
    width: 100%;
  }
}

.input-field-group_inner.states-checkbox.customer {
  margin-top: 1rem;

  .input-field {
    border: unset !important;
  }
}

.terms-conditions-wrapper {
  .error-message {
    color: #d4311e;
  }
}

.form.loc-borrower {
  .section {
    .section_heading {
      h1,
      h2 {
        text-transform: none !important;
      }
    }
  }
}

.additional-guarantors_remove {
  margin-top: 1rem;
  margin-left: 1rem;
}

.sign-in-here {
  text-decoration: underline !important;
  font-size: 1.5rem !important;
}

// broker signup overrides
.layout_base {
  &#broker-signup {
    .form.loc-borrower {
      width: 80%;
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4rem !important;
      margin-top: 6rem !important;

      p,
      h1,
      label {
        color: white !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
      }

      h1 {
        border-color: white !important;
      }

      section.form-submit {
        div p {
          margin-left: 0 !important;
        }

        p {
          margin-left: 1rem !important;
        }

        button {
          margin-top: 2rem;
        }

        .terms-inner_body {
          color: black;

          h1,
          p {
            color: black !important;
          }
        }
      }
    }
  }
}

// privacy policy close override
.privacy-conditions-wrapper .terms-modal .terms-inner .close button.terms-close {
  margin: 0 !important;
  background: none;
}
