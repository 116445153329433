@import '../../../css/variables.scss';


.modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    background-color: $modal-bg-white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s ease;
    z-index: 1000;
    box-sizing: border-box;

    .modal-inner {
        background-color: #fff;
        position: relative;
        max-width: 900px;
        max-height: 70%;
        width: 70%;
        overflow: auto;
        padding: 2rem 2rem 0rem 2rem;
        transition: 0s ease;
        opacity: 0;
        border-radius: 0.3rem;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.39);

        @include tablet {
        width: 60%;
        height: auto;
    }

    .modal-close {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        cursor: pointer;
        z-index: 100;

        &:hover, &:focus {
            color: #0086CA !important;
            outline: 2px solid #0086CA;
            outline-offset: -2px;
        }

        .modal-close_icon {
            font-size: 1.5em;
            margin: 2rem 2rem 2rem 2rem;
            pointer-events: none;
        }
    }

    .modal-inner_body {

        h1 {
            border-bottom: 2px solid #000000;
            padding-bottom: .9rem;
        }
        
        p {
            padding: .5rem 0;
            font-size: 0.8125rem;
        }

        input {
            pointer-events: none;
        }
    }
} 

&.is-active {
    opacity: 1;
    pointer-events: all;

    .modal-inner {
        opacity: 1;

        .modal-inner_body {
            input {
                pointer-events: all;
            }
        }
    }
}
}
