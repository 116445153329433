@import "../../../../../../css/variables.scss";

.construction-escrow {

  .section_heading {
    border-bottom: none !important;
  }
  
  .section_content {
    padding-top: 0;
  }

  .escrow-bar {
    width: 100%;
    height: 2rem;
    border: 2px solid $dark_blue;
    overflow: hidden;
    border-radius: 0.15em;
    margin-bottom: 1rem;

    .escrow-bar-inner {
      width: 100%;
      transition: width 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
      transition-delay: 0.1s;
      height: 2rem;
      background-color: $green_button
    }
  }
}