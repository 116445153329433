
    .section-new {
        &#form-select-type {
        .section_content {
            padding: 1rem 0 0 0 !important;

            .input-field {
                padding: 0 0 !important;
            }
        }
    }
}