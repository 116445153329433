@import '../../../../../css/variables.scss';

#deal-header {

    .section_heading {
        .react-loading-skeleton {
            width: calc(100% - 1rem);
        }

        h2 {
            span {
                display: block;
                width: 100%;

                img {
                    width: 5rem;
                    height: 2rem;
                }
            }
        }
    }

    div.deal-header-body {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0.1rem;

        .deal-header-body_inner  {
            border-radius: 0.25rem;
            width: 100%;
            // min-height: 30rem;

            // all inner wrappers (both sides
           .inner {
            border: 1px solid #F1F1F1;
            box-sizing: border-box;
            border-radius: 0.25rem;
           }

           @include tablet-lg() {
               margin-bottom: 0;
            //    min-height: 30rem;
           }

            // left side (image and card)
           &.first {
            //    border: 0.1em solid fuchsia;
             position: relative;
             overflow: hidden;
             display: block;
             width: 100%;
            

             @include phone-med() {
                 display: flex;
             }

             @include tablet() {
                display: flex;
                margin-bottom: 0rem;
            }
             
             @include tablet-lg() {
                 flex-direction: column;
                flex-basis: 30%;
                // display: block;
             }

             .inner {
                 flex-grow: 1;
                
                 height: 16rem;
                 margin-bottom: 1rem;

                 @include tablet-lg() {
                     margin-bottom: 0;
                     height: unset;
                 }

                 // image container child
                &.image {
                    // height: 210px;
                    // flex-basis: 50%;
                    border: none;
                    position: relative;
                    display: flex;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 0.25rem;

                 
    
                    @include phone-med() {
                        // flex-basis: 50%;
                    }

                    @include phone-med() {
                        flex-basis: calc(50% - 0.5rem);
                        margin-right: 0.5rem;
                    }

                    @include tablet() {
                        // margin-right: 0rem;
                    }

                    @include tablet-lg() {
                        // flex-basis: 60%;
                        flex-grow: 1;
                        flex-basis: 50%;
                        margin-right: 0rem;
                    }
                }

                // contact card child
                &.card {
                    overflow: hidden;
                    padding: 1rem;
                    flex-grow: 1;
                   
                    position: relative;
                    // flex-basis: 50%;

                    @include phone-med() {
                        flex-basis: calc(50% - 0.5rem);
                        margin-left: 0.5rem;
                    }

                    @include tablet() {
                        margin-bottom: 0;
                    }
                    
                    @include tablet-lg() {
                        margin-left: 0;
                        
                        flex-basis: calc(45.5% - 1rem);
                        margin-top: 1rem;
                        //   height: 183px;
                    }
                    
                }
             }
                
                .react-loading-skeleton {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .space-holder-text {
                    position: absolute;
                    z-index: 100;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    text-align: center;

                  div {
                    img {
                        width: 9rem !important;
                        position: relative;
                        border: none;
                    }

                    span {
                        display: block;
                        margin-top: 0.5rem !important;
                    }
                  }
                }
            }

            // right side - details OR status
            &.second {
                min-height: 15rem;
              
                .inner {
                    border: none !important;
                }
                
                @include tablet-lg() {
                    min-height: 30rem;
                    flex-grow: 1;
                    flex-basis: calc(60%);
                    padding: 0 0 0 1rem;
                }

                .react-loading-skeleton {
                    line-height: 1.2; // closing gap at top
                }

                .inner {
                    height: 100%;
                }
            }
        }
    }
}