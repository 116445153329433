@import '../../../../../../assets/css/variables';

.search-loan-modal {
    position: fixed;
    width: 40vw;
    height: 30vh;
    min-width: 340px;
    min-height: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;

    .body {
        display: flex;
        flex-direction: column;

        main {
            margin-top: 0rem !important;
        }

        h1 {
            text-align: center;
            width: 100%;
            border-bottom: 2px solid $dark_blue;
        }

        label {
            color: $dark_blue;
        }

        span {
            color: $error-red;
        }

        .error {
            input {
                border: 1px solid $error-red !important;
            }
        }

        .pt4 {
            // padding-top: 4rem;
        }

        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            align-items: baseline;
        }
            
        .column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;
            padding: 1rem;
        }
    
        .input-field {
            height: 6rem;
        }
    
        span.error-message {
            display: block;
            margin: 0.5rem 0 !important;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        tr, p {
            color: black !important;
        }

        td, th {
            text-align: center;
            border-right: solid 1px black; 
            border-left: solid 1px black;
        }

        th {
            background-color: $dark_blue;
            color: white;
        }

        tr {
            border: 1px solid black;
        }

        .back-btn, .close-btn {
            display: inline-block;
            position: absolute;
            font-size: 2rem;
            top: 1.2rem;
            left: 1.2rem;
            svg {
                color: $dark_blue;
                opacity: 0.8;
            }
        }

        .close-btn {
            right: 1.2rem;
            left: unset !important;
        }

        input[type='checkbox'] {
            width: 12px !important;
            height: 12px !important;
        }

        .checkbox-input {
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 16px !important;
            height: 12px !important;

            input {
                width: 12px !important;
                height: 12px !important;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        h2 {
            font-size: 2rem;
            text-align: center;
            text-decoration: none !important;
            border-bottom: unset !important;
        }


        @media screen and (max-width: 400px) {
            .hide-mobile {
                display: none;
            }
        }
    }
}