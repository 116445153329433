*, .form {

.row {
    display: flex;
    align-items: flex-end;
    padding: 0 0;
    justify-content: space-between !important;
    flex-wrap: wrap;

    &.start {
        justify-content: flex-start !important;
    }

    &.end {
        justify-content: flex-end !important;
    }

    &.center {
        justify-content: center !important;
    }
    
    .column {
        display: block !important;
        flex-basis: 100% !important;
        padding: 0 !important;;

        @include tablet-lg() {
            flex-basis: calc(50% - 0.5rem) !important;
        }

        @include desktop() {
            flex-basis: calc(25% - 0.75rem) !important;
        }

        .row {
            .column {

            @include tablet-lg() {
            flex-basis: calc(50% - 0.5rem) !important;
        }
            }
        }
    }

    &.grow-1 {
        @include desktop() {
            justify-content: center !important;

        .column {
            flex-basis: calc(100%) !important;
        }
    }
    }

    &.grow-2 {
        @include desktop() {
            justify-content: space-between !important;

            .column {
                flex-basis: calc(50% - 0.5rem ) !important;
            }
        }
    }

    &.grow-3 {
        @include desktop() {
            justify-content: flex-start !important;

            .column {
                flex-basis: calc(33.33% - 0.75rem ) !important;
            }

            div:nth-child(3n + 2),  span:nth-child(3n + 2) {
                width: 33%;
                margin: 0 calc(3.25rem / 3 ) !important;
            }
        }
    }

    &.grow-4 {
        @include desktop() {
            justify-content: flex-start !important;

            .column {
                flex-basis: calc(25% - 1rem) !important;
            }

            div:nth-child(2) {
                width: 25%;
                // color: red;
                margin: 0 0.67rem 0 1.33rem;
            }

            div:nth-child(3n + 3) {
                    // color: blue;
                    margin: 0 0.67rem 0 1.33rem;
            }

            div:nth-child(4n + 3)  {
                // color: fuchsia;
                width: 25%;
                margin: 0 1.33rem 0 0.67rem;
            }
        }
    }


}
}