@import "../../../../assets/css/_variables";

.select-application-new {

  padding: 0rem;

  label {
    line-height: 1.6;
  }

  h1 {
    color: $dark_blue;
    border-bottom: 2px solid $dark_blue;
    padding-bottom: .5rem;
    margin-bottom: 1rem;

    @media (max-width: 425px) {
      font-size: 1.375rem;
    }
  }

  ul {
    // padding-top: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include tablet-lg() {
      // padding-top: 2rem;
      justify-content: flex-start;
    }


    li {
      text-align: center;
      display: flex;
      width: auto;
      flex-basis: 50%;
      // align-items: flex-start;
      // margin-right: 0rem;
      // margin-bottom: 3rem;
      // justify-content: center;
  
      

      @include tablet-lg() {
        flex-basis: 20%;
        margin-right: 1rem;
        margin-bottom: 1rem;
        // justify-content: flex-start;
      }


      a {
        text-decoration: none !important;
        border-radius: 0.2rem;
        font-style: unset !important;
        padding: 1rem 1rem 0 1rem;
        width: 100%;
        height: 100%;
        // border: 1px solid #F1F1F1;
        display: flex;
        align-items: center;
        margin-right: 0rem;
        margin-bottom: 2rem;
        justify-content: flex-start;
        flex-direction: column;

        @include tablet-lg() {
          margin-bottom: 1rem;
          padding: 1.5rem 1rem 0 1rem;
        }
     

        &:hover {
          p {
            text-decoration: underline;
          }
        }

        &:focus {
          outline-offset: -3px;
        }

        img {
          height: 3.5rem;
        

        &.fixnflip {
          height: 3.85rem !important;
        }

        &.multifamily {
          height: 5rem;
          margin-top: -0.9rem;
        }

        &.rental {
          height: 4.25rem;
          margin-top: -0.4rem;
        }

        &.construction {
          margin-top: 0.4rem;
        }
      }

        @media (max-width: 425px) {
          padding-bottom: 3rem;
        }

        p {
          width: 100%;
          max-width: 181px;
          font-size: 0.9375rem !important;
          text-align: center;
          padding-top: 1rem;
          // line-height: 1.2;
          margin: 0 !important;
          margin-left: 0 !important;
          color: #0091d0;
        }
      }
    }
  }
}