@import '../../../../../../css/variables.scss';

.draw-status {
  // padding: 2rem 0 !important;

  @media (max-width: 425px) { 
    .hide-on-mobile {
      display: none !important;
    }

    .search-header {
      grid-template-columns: 8fr 4fr !important;
    }
  }

  .search-header-left {
    ul {
      li {
        display: inline-block;
        padding-right: 1rem;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .draw-status-box {
    // padding: 2rem 0rem;

    ul {
      li {
        display: inline-block;

        img {
          width: 100%;
          max-width: 38px;
        }

        ul {
          padding: 1rem 4rem 0 0 !important;
          
          li {
            display: block;
            padding: 0.25rem 0;
            text-align: center;
            font-size: 0.8125rem;

           

            &:last-child {
              padding-top: .3rem;
            }

            &:nth-child(2) {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .draw-icon-group {
    display: flex;
    flex-wrap: wrap;
  }

  .draw-icon {
    cursor: pointer;
    display: block;
    // padding: 1rem !important;
    // padding-right: 0 !important;
    a {
      color: black !important;

      &:hover {
        li {
          color: #0091D0;
        }
      }
    }

    img {
      padding: 0.15rem;
      border-radius: 100%;
      transition: 0.1s;
    }

    &.is-active {
      font-weight: bolder;
      color: #0D234A;

      img {
        background: #0D234A;
      }
    }
  }
}