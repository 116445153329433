.capex-body {

    p {
        padding: 0 !important;
    }

    textarea {
        background-color: #f1f1f1;
        padding: 1rem;
        width: 100%;
        height: 3.5rem;
    }

    // .doc-upload-custom {
    //     label {
    //         color: black !important;
    //         // font-family: unset !important;
    //     }

    //     input { 
    //         // background: white;
    //         margin: 0.5rem 0 0 0 !important;
    //         padding: 0 !important;
    //     }
    // }
}