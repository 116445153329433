@import '../../../../assets/css/variables';

.app-top-bar {
    display: none;
    position: relative;
    border-top: 1rem solid #0c224B;
    border-right: 1rem solid #0c224B;
    border-bottom: 2px solid #10295B;
    max-width: 1200px;

    .top-bar-left {
        
        .form-body {
            display: flex;
            align-items: center;
        }

        input {
            border: 1px solid black;
            height: 2.35rem;
            width: 20rem;
            margin-right: 1rem;
            padding: 0.5rem 0.5rem;
        }

        .button-submit-rf  {
            margin: 0;
            height: 2rem;
            min-width: 12rem;
            padding: 1rem 1rem;
            font-size: 1rem;
        }
    }

    .top-bar-right {
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
    }

    @include tablet() {
        // height: 3.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: #EEEEEE; 
        color: #10295B; 
        padding: 0rem 1.5rem 0 2rem; 
        text-align: right;
    }

    @include desktop() {
        padding: 0rem 3.5rem 0 4rem; 
    }

    .decor {
        background: #10295B;
        height: 1rem;
        width: 1rem;
        position: absolute;

        .inner {
            height: 100%;
            width: 100%;
            background-color: #EEEEEE; 
        }

        
        &.top-left {
            top: 0;
            left: 0;

            .inner {
                border-top-left-radius: 0.25rem;
            }
        }

        &.top-right {
            top: 0;
            right: 0 !important;

            .inner {
                border-top-right-radius: 0.25rem;
            }
        }

        &.bottom-left {
            bottom: 0;
            left: 0;

            .inner {
                border-bottom-left-radius: 0.25rem;
            }
        }

        &.bottom-right {
            bottom: 0;
            right: 0;

            .inner {
                border-bottom-right-radius: 0.25rem;
            }
        }
    }
    
}