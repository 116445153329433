@import "../../assets/css/_variables";

.sign-up {
  padding: 4rem !important;
  height: 78vh;
  &.set-password {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 17rem);

     .content {
      //  max-width: 50rem;
       overflow: hidden;
       display: flex;
       justify-content: center;
       flex-direction: column;
       text-align: center;

       input {
         flex-basis: 100%;
         width: 100%;
         width: 30rem;
       }

       button {
         max-width: 33rem;
       }

      h1, h3 {
        text-align: center !important; 
    }
.sign-up-left1 {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
   
     }
  }
}

.password-reset-form {
  background: red;
  padding: 4rem 5rem;
  

  @media (max-width: 425px) { 
    padding: 1.875rem 1.5625rem !important;
    padding-bottom: 9rem !important;
  }

  .auth-error {
    padding-bottom: 1rem;
  }

  h1 {
    color: $dark_blue;
    border-bottom: 2px solid $dark_blue;
    padding-bottom: .5rem;
    margin-bottom: 2rem;

    @media (max-width: 425px) { 
      font-size: 1.375rem;
    }
  }

  h2 {
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    font-size: 1.375rem;
    color: $dark_blue;
    padding-bottom: 1.5rem;
  }

  p {
    line-height: 1.6;
  }

  .floating {
    margin-bottom: 1rem;
  }

  input {
    width: 92%;
    // background-color: $light-grey !important;
    // color: $darker-grey;
  }

  .submit {
    background-color: $blue_button;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8125rem;
    padding: 1.25rem 2.75rem !important;
    margin-right: 1px;
    cursor: pointer;
  }
}

.password-reset-message {
  display: none;
}

#enteremail {
  width: 100%;
  max-width: 500px;
}