/* Colors */
$dark_blue: #0C224B;
$medium_blue: #1F436D;
$lighter_blue: rgba(99, 170, 208, 0.267);
$lighter__blue: #63A9D0;
$lighter___blue: #0091D0;
$white: #ffffff;
$light-grey: #F1F1F1;
$darker-grey: #83838F;
$another-grey: #D9D9D9;
$another-grey-two: #DDDDDD;
$darker_grey: #707070;
$black: #000000;
$orange: #EA7806;
$blue_button: #0091D0;
$green_button: #038B57;
$error-red: #D62F10;
$error-red-two: #F8D8D2;
$half-white: #919BB0;


// Breakpoints - mobile out
@mixin phone-med {
    @media screen and (min-width: 426px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 769px) {
        @content;
    }
}

// Side Nav Revealed at 800px
@mixin tablet-med {
    @media screen and (min-width: 801px) {
        @content;
    }
}

@mixin tablet-lg {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1400px) {
        @content;
    }
}

@mixin desktop-xl {
    @media screen and (min-width: 1600px) {
        @content;
    }
}

