.payment-history {
    position: relative;
    
    &.expandable {
        .payment-history_legend {
            display: flex;
            background: #F1F1F1;
            border-bottom-left-radius: 0.15rem;
            border-bottom-right-radius: 0.15rem;
            
            span {
                display: inline-block;
                flex-basis: 33.33%;
                padding: 0.75rem 1rem 0.5rem 1rem;
                font-weight: bold !important;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}