@import "../../../../assets/css/_variables";

.nav-main-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: 0;
    left: 0;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
  
    &.is-active {
        height: 100vh;
        overflow-y: auto; 
    }

    @include tablet {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 99.85vh;
    }
    
    .nav-modal {
        background-color: #f1f1f1b9;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        height: 100vh;
        width: 100vw;
        z-index: -1;
        position: absolute;
        opacity: 0;
        transition: 0s;
        pointer-events: none;

        &.is-active {
            transition: 0.1s;
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
        }

        @include tablet {
            display: none;
        }
    }

    .main-nav_top {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 2rem;
        position: relative;
        background: #0c224B;

        @include tablet {
            position: relative;
        }

        .nav-toggle {
            left: 0;
            color: white;
            font-size: 1.5rem;
            position: absolute;
            padding: 1.5rem 2rem;
            cursor: pointer;

            @include tablet {
                display: none;
            }
        }

        // nav logo wrapper mobile and desktop
        .nav-logo {

            svg {
                width: 10rem;
                color: white;
                fill: white;
            }
        
            @include tablet {
                padding: 2em 2em 4em 2em;
                width: calc(234px + 4em);
                height: calc(140px + 4em);

                svg {
                    width: unset;
                }
            }

            // mobile image
            .mobile {
                display: block;

                @include tablet {
                    display: none;
                }
            }

            // tablet/desktop image
            .desktop {
                display: none;

                @include tablet {
                    display: block;
                }
            }
        }

    }

    .main-nav_body {
        background: #0c224B;
        position: relative;
        top: 0;
        transform: translateY(-101%);
        transition-duration: 0s;
        transition-timing-function: ease;
        transition-delay: 0s;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.24);
        opacity: 0;

        &.is-active {
            transform: translateY(0%);
            transition-duration: 0.3s;  
            opacity: 1;
        }

        &.is-active > ul > .topAdjustment {
            margin-top: 3rem;
        }

        @include tablet {
            transform: translateY(0%);
            flex-basis: 100%;
            transition-duration: 0.0s;
            box-shadow: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between; 
            opacity: 1;
        }

        .main-nav-footer {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: white;
            font-size: 1rem;

            .main-nav-footer_inner {
                padding: 2rem 1.5rem;

                ul {
                    li {
                        padding: 0;
                        border: none;
                        
                        a {
                            font-size: 1rem !important;
                            padding: 0.5rem 0 !important;
                            margin: 0;
                            display: inline-block;
                            
                            color: white;

                            &:hover {
                                color: #0091D0 !important;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                p {
                    font-size: 0.8rem !important;
                    padding: 0.5rem 0 !important;
                    margin: 0 !important;

                    a {
                        font-size: 0.8rem;
                        color: white;

                        &:hover {
                            color: #73A8CC !important;
                        }
                    }
                }
            }
        }
    }


    // individual links below should not need to change baed on media width
    li.main-nav-new_link {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        border-radius: 0 !important;
        border-left: none !important;
        padding: 0 !important;
        border-top: none;
        border-bottom: 0.015em solid #86b5d669;
        background: #0c224B;
        min-height: 3rem;

        &:first-of-type {
            border-top: 0.015em solid #86b5d669;
        }

        &:hover {
            background-color: initial !important;
            border-radius: 0 !important;
        }

        a:focus, button:focus {
            z-index: 1;
            outline: 3px solid #0091D0;
            outline-offset: -3px;
            border-radius: 0.2em !important;
        }

        a {
            &.is-active {
                color: #0091D0 !important;

                .button-icon {
                    color: #0091D0 !important;
                }
            }

            svg {
                color: white !important;
                fill: white !important;
            }
        }
    
        a, button.nav-item {
            color: rgb(255, 255, 255);
            display: block;
            width: 100% !important;
            text-align: left;
            padding: 0.75em 0 0.75em 1em;
            border-left: 0.5em solid rgba(255, 0, 0, 0);

            &.logout {
                padding-left: 1.5rem;
                span:last-child {
                    position: relative;
                    top: -0.2rem;
                    left: 0.15rem;
                }
            }

            .button-icon {
                font-size: 1.35rem !important;
                color: #ffffff;
            }

            div {
                display: flex;
                align-items: center;
                pointer-events: none;

                svg {
                    width: 1.5em;
                    color: white !important;
                    fill: white !important;
                }
            }
            
            &:hover {
                border-left-color: #0091D0 !important;
                    color: #0091D0 !important;
                
                    .button-icon {
                        color: #0091D0;
                    }
            }

           ul {
            margin-top: 0.25rem;
            margin-left: 3rem;

               svg {
                   color: white !important;
                   fill: white !important;

                   .a {
                       color: white !important;
                       fill: white !important;
                   }
               }

               li {
                   border-top: none !important;
                   border-left: none !important;
                   padding: 0 !important;
                   margin: 0 !important;

                   &:last-of-type {
                       border-bottom: none !important;
                   }

                   &:hover {
                    border-left: none !important;
                    background-color: initial !important;
                   }

                a {
                    border-left: none !important;
                    padding-left: 0;
                    line-height: 1.3;
                    // padding-right: 2rem;
                    // padding-left: 1rem;
                    padding: 0.5rem 2rem 0.5rem 0rem;
                    border-bottom: 0.015em solid #86b5d669;

                   

                    &:hover {
                        color: #0091D0;
                        border-left: none !important;
                    }

                    &:focus {
                        z-index: 1;
                        outline: 3px solid #0091D0;
                        outline-offset: -3px;
                        border-radius: 0.2em !important;
                    }
                }

                i {
                    font-style: italic;
                }

                &:last-of-type {
                    a {
                        border-bottom: none !important;
                    }
                }
            }
        }
            
            span  {
                margin-right: 1rem;
                pointer-events: none;

                &:nth-of-type(3) {
                    position: absolute;
                    right: 0;
                }
                
            }
        }
    }
}

