#customer-resources, #customer-resources_forms, #customer-resources_loan-lookup {

    p {
        padding-top: 0;
        padding-bottom: 1rem;
    }

    ul {

        li {
            display: flex;
            align-items: center;
            margin: 0.5rem 0;

            img {
                width: 2rem;
                height: auto;
            }

            a, button {
                padding: 0.25rem 1rem;
                color: #0091D0;
                text-decoration: none;
            }
        }
    }

    button {
        text-decoration: underline;
    }
}