// ----- DESCRIPTION ---------------------------------------------------
// - Overrides for Form elelemts from the Design Audit.

// - All Future form style updates should be updated here, with the idea 
//   being to gradually replace and completely remove legacy styling.

// - Future new forms should still generally follow the HTML and classes
//   that already exist (form > form-body > input-field-group > 
//   input-field > label/input.  Add class "row" input-field-group and 
//   class "column" to input fields to   Place NEW component-specific 
//   styles in their respective directories!


@import '../../assets/css/variables'; // import variables

@media screen and (min-width: 1400px) {
    .form .form-body .row .column {
        flex-grow: 1;
    }

    .form .form-body .row {
        justify-content: space-between !important;
        gap: 1rem;
    }
}

.percentInput { position:relative; }
.percentInput span { position: absolute; right: 16px; top:12px; font-size: 24px }
.num_percent { padding-right: 12px; }

.layout_white-label-applications .section_heading h1 {
    //border-bottom: none !important;
}

.input-field .full-width .custom-scroll ul li label {
    margin: 1rem 1rem !important;
}

@media screen and (max-width: 600px) {
    .input-radio-fields tr td {
        width: 100% !important;
        flex-basis: 100% !important;
    }

    #app-form .additional-guarantor-form .row.property-row-top .section.select-property-type ul {
        display: inline-flex !important;
        flex-wrap: wrap !important;
        width: 100% !important;

        li {
            flex-basis: 40% !important;
            width: 40% !important;
            float: unset !important;
            position: relative;

            input {
                position: absolute;
                width: 18px !important;
                height: 18px !important;
            }

            label {
                margin-left: 2.5rem !important;
            }
        }
    }

}

#application-loc h2 {
    text-transform: capitalize !important;
}

// ---- v Main Form Wrapper v ------------------------------------------
.form {
    padding: 0 0 !important;
    font-size: 14px;
    // border: 1px dashed fuchsia;
    // color: red;
    // margin-bottom: 8rem;
    
    // ---- v General Legacy Overrides v -------------------------------
   
    * {
        position: relative;
        box-sizing: border-box !important;
        border-radius: 0rem;     
    }

    button {
        border: none;
    }

    input, select, input[type=file] {
        border: none !important;
        width: calc(100%);
        background-color: #f1f1f1;
        border: 0.15rem solid #f1f1f1;
        padding-left: 1rem;
        border-radius: 0.2rem !important;

         &:focus {
            outline: 0.15rem solid #0091d0;
            outline-offset: -3px;
            background-color: #f1f1f1;
            transition: 0.1s;
        }
    }

    input, input[type=text], input[type=number], input[type=date], textarea,
    select {
        height: 3.5rem !important;
    }

    // input[type=file] {
    //     padding: 1rem !important;
    // }

    label {
        display: block;
        margin: 0.5em 0;
        font-size: 1em !important;
        overflow-wrap: break-word; 
    }

    h1 {
        color: #0D224B !important;
        border-bottom: 2px solid #0c224b !important;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
    }

    h2 {
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 1rem;
        color: #10295B !important;
        border-color: #10295B  !important;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
    }

    hr {
        height: 0.1rem;
        border: none;
        background-color: #d9d9d9c9;
        box-shadow: none;
        margin: 1rem !important;
    }

    p {
        font-size: 1em !important;
        font-weight: normal !important;
        overflow-wrap: break-word; 
    }

    input {
        visibility: visible !important;
    }

    // ---- v Form Error Alert (top of page - possibly?) v ---------------
    .form-alert {
        border: 0.15em solid #D4311E;
        background: #F7D8D3;
        color: #D4311E;
        display: none;
        margin: 2em 0;
        padding: 1rem;
        justify-content: flex-start;
        align-items: center;

        &.error {
            display: flex;
        }
        
        .alert-icon {
            font-size: 3rem;
            padding: 0 1rem 0 0;
            flex-basis: auto;
        }

        .alert-message {
            h2 {
                text-transform: uppercase;
                font-weight: 800;
                margin-bottom: 0.25em;
                padding-left: 0 !important;
            }
        }
    }

    // ---- v Main Body of Form v ----------------------------------------
    .form-body {
        margin: 0 !important;

        hr {
            padding: 0 !important;
            margin: 1rem 0 !important;
        }

        p {
            // margin: 0 0 1rem 0 !important;
            // background: red !important;
        }

        .error-message {
            font-size: 1rem !important;
            display: flex !important;
            align-items: center;
            height: 1rem !important;
            display: none !important;
            
            input, select {
                outline-color: transparent !important;
                color: #D4311E !important;
            }
        }

        .error {
            outline-color: transparent !important;

           span.error-message {
            display: none !important;
           }

           color: #D4311E !important;

           input, select {
            outline-color: transparent !important;
            color: #D4311E !important;
        }
        }

        .section {
            padding:  0rem 0;
            width: 100%;

            h1 {
                color: #0D224B !important;

                &.heading-third-party {
                    margin-top: 2rem !important;

                    &.first {
                        margin-top: 3rem !important;
                    }
                }
            }

            .section_heading {
                padding: 0;

                p, h1, h2 {
                    padding: 0;
                    // color: fuchsia !important;
                }

                h1 {
                    text-transform: none;
                    font-weight: 600;
                    font-size: 1.75rem;
                    padding-bottom: 0.5rem !important;
                    color: #0c224b !important;
                    border-bottom: 2px solid #0c224b;
                    margin: 0 !important;
                    height: 3rem;
                    padding: 0 !important;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 1.75rem !important;
                    white-space: nowrap;
                    overflow-x: hidden;
                    margin-bottom: 1rem !important;
                    margin-top: 1rem !important;

                    &.first {
                        margin-top: 0rem !important;
                    }
                }
            }

            &.borrowing-entities {

                label {
                    padding: 0 1rem 0 0!important;
                    display: flex;
                    align-items: center;

                  
                        &.focus-within {
                            outline: none !important;
                        }
                    }

                .additional-guarantor {
                    gap: 2.5rem !important;
                    flex-wrap: nowrap !important;
                }

                @media screen and (max-width: 980px) {
                    .additional-guarantor {
                        flex-wrap: wrap !important;
                        width: 100% !important;
                        gap: 1rem !important;

                        .input-field {
                            width: 100% !important;
                            flex-basis: 100% !important;
                        }
                    }
                }

                .input-field-group {
                    align-items: flex-start !important;
                    //gap: 2.5rem !important;
                    //flex-wrap: nowrap !important;
                    .loc-borrower {
                        flex-basis: 45%;
                    }

                    @media screen and (max-width: 770px) {
                        .loc-borrower {
                            flex-basis: 100%;
                        }
                    }


                    .input-field {
                        padding: 0 0 !important;
                    }
                }
                .additional-property {
                    margin: 0 0 1rem 0 !important;

                    h1 {
                        margin: 0 !important;
                        height: 3rem;
                        padding: 0 !important;
                        border-bottom: 2px solid #0c224b;
                        color: #0D224B !important;
                    }
                }

                p.please-fill {
                    margin-bottom: 1rem !important;
                }

                p:last-child {
                    // color: red !important;
                    margin-bottom: 1rem !important;
                }

                ul.select-borrower-customer {
                    margin-top: 0.25rem !important;
                 

                    li {
                        display: flex;
                        align-items: center;

                        label {
                            padding: 0 1rem 0 0!important;
                            display: flex;
                            align-items: center;

                          
                                &.focus-within {
                                    outline: none !important;
                                }
                            

                            input[type="radio"] {
                                margin: 0 0.5rem 0 0 !important;
                                margin-right: 0.5rem !important;
                                display: inline-block !important;

                                &:focus {
                                    outline: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .row {
            align-items: flex-end;
            padding: 0 0;
            justify-content: space-between!important;
            
            .column {
                flex-basis: 100% !important;
                padding: 0 !important;;

                @include tablet-lg() {
                    flex-basis: calc(50% - 0.5rem) !important;
                }

                @include desktop() {
                    flex-basis: calc(25% - 0.75rem) !important;
                }

                .row {
                    .column {

                    @include tablet-lg() {
                    flex-basis: calc(50% - 0.5rem) !important;
                }
                    }
                }
            }

            &.grow-2 {
                @include desktop() {
                    .column {
                        flex-basis: calc(50% - 0.5rem) !important;
                    }
                }
            }

            &.grow-3 {
                @include desktop() {
                    justify-content: flex-start !important;

                    .column {
                        flex-basis: calc(33.33% - 0.75rem ) !important;
                    }
    
                    div:nth-child(3n + 2) {
                        width: 33%;
                        margin: 0 calc(3.25rem / 3 );
                    }
                }
            }

            &.grow-4 {
                @include desktop() {
                    justify-content: flex-start !important;

                    .column {
                        flex-basis: calc(25% - 1rem) !important;
                    }

                    div:nth-child(2) {
                        width: 25%;
                        // color: red;
                        margin: 0 0.67rem 0 1.33rem;
                    }

                    div:nth-child(3n + 3) {
                            // color: blue;
                            margin: 0 0.67rem 0 1.33rem;
                    }

                    div:nth-child(4n + 3)  {
                        // color: fuchsia;
                        width: 25%;
                        margin: 0 1.33rem 0 0.67rem;
                    }
                }
            }
        }
        
        // - catch-all for input fields outside of input-field-group (don't do this)
        .input-field {
            flex-basis: 100%;
            width: 100%;
            display: block;
            padding: 0rem 0 !important;
            // border: 1px solid fuchsia;
            margin-bottom: 1rem !important;

            @include tablet-lg() {
                flex-basis: calc(50% - 0.5rem);
            }
        }

        // - main wrapper for input-field(s)
        .input-field-group {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end !important;
            border: none;
            padding: 0rem 0;
            border: none !important;

            .input-field {
                padding: 0 !important;

                label {
                    margin: 0 !important;
                }
            }

          
            // - inner for more complex/hidden inputs in input-fields
            .input-field-group_inner {
                flex-basis: calc(100%);
                display: flex;
                flex-wrap: wrap;

                @include tablet {
                    flex-basis: calc(50%);
                }
            }


            &.single-property-tf-qustions {
                display: flex;
        
                .input-field {
                    min-height: 11rem;
                   
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        list-style: none;
                    }
                }
            }

          
            // ----- v New Borrowing Entity Information Section v --------------------------------
            &.requested-loan-info {
                align-items: flex-start !important;

                .input-field.full-width {
                    padding: 0 !important;

                    h1 {
                        margin-bottom: 0 !important;
                        margin-top: 0 !important;
                        color: #0D224B !important;
                    }

                    .col-left, .col-right {
                        flex-basis: 100%;
                        padding: 0 !important;
                        margin: 0 !important;
                        width: 100%;

                        @include tablet-lg() {
                            flex-basis: calc(50% - 0.5rem);
                        }

                        // @include desktop() {
                        //     flex-basis: calc(33.33% - 1rem);
                        // }
                    }
                }

                &.borrower-info-new {
                    
                    .col-left, .col-right {
                        flex-basis: 100% !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        margin-bottom: 0 !important;
                        margin-right: 0 !important;
                        padding-bottom: 0 !important;
                       
                        @include tablet-lg() {
                            flex-basis: calc(50% - 0.5rem) !important;
                        }

                        // @include desktop() {
                        //     flex-basis: calc(33.33% - 1rem);
                        // }

                          // states pick list
                        .states-checkbox {
                            .input-field {
                                margin-bottom: 1rem !important;
                                height: 260px;
                                overflow-y: hidden;
                                border: none !important;

                                label {
                                    padding: 0.25rem 0rem !important;
                                }

                                ul {
                                    height: 230px !important;
                                    border: 0.1rem solid #F1F1F1;

                                    li {
                                        margin-right: 0 !important;
                                        margin-left: 0 !important;
                                        border-bottom: 1px solid #F1F1F1;

                                        &:last-child {
                                            border-bottom: none !important;
                                        }

                                        &:focus-within {
                                            outline: 3px solid #0091D0 !important;
                                            outline-offset: -3px;
                                            border-radius: 0.2em;
                                        }
                                        
                                        label {
                                            padding: 0.35rem 1rem !important;
                                            display: flex;
                                            align-items: center;

                                           input {
                                               &:focus {
                                                   outline: none !important;
                                               }
                                           }
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

          

            .error-message {
                height: 3rem !important;
                max-height: 0;
            }

            &.form-errors {
                .error-message {
                    max-height: 2rem;
                }
            }

            &.error {
                background: white;
                outline: 0.15rem solid #D4311E;
            }

            .input-field {
                flex-basis: 100%;
                width: 100%;
                display: block;
                padding: 1rem 0 !important;
                // border: 1px solid greenyellow;

                p {
                //  padding: unset !important;   
                    font-weight: normal !important;
                    margin-bottom: 1rem !important;
                }

                @include tablet {
                    flex-basis: calc(50%);

                    &:nth-of-type(odd) {
                        margin-right: 0em;
                    }
                }
    
                &.error {
                    label {
                        color: #D4311E !important;
                    }
    
                    input {
                        background: #F7D8D3;
                        outline: 0.15rem solid #D4311E;
                    }

                    select {
                        outline: 0.15rem solid #D4311E;
                    }
    
                    * {
                        &:focus {
                            outline: 0.15rem solid #0091d0;
                        }
                    }

                    .error-message {
                        min-height: 2rem !important;
                    }
                }
    
                &.flex {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0 !important;

                    .input-field_inner {
                        flex-basis: 50%;
                        padding: 0 1rem;
                    }
                }

                &.full-width {
                    flex-basis: 100% !important;
                }
    
                .error-message {
                    color: #D4311E;
                    margin: 0.5em 0;
                    font-weight: bold;
                    display: block;
                    padding: 0em 0 !important;
                    opacity: 0;
                    transition: 0.1s;
                    height: 0rem;
                    overflow: hidden;
                    max-height: 0rem;
                    padding: 0 !important;
                    // border: 0.15em dashed green;
                }

                ul {
                    display: flex;
                    // color: red;
                    flex-direction: row;
                    padding: 0 !important;
                    justify-content: flex-start;

                    li {
                        display: flex !important;
                        justify-content: flex-start;
                        align-items: center;
                        flex-basis: initial;
                        margin: 0rem 0.5rem !important;
                        padding: 1rem 0.5rem !important;
                        

                        label {
                            // border: 1px solid rgba(255, 0, 0, 0.11);
                            display: flex;
                            align-items: center;
                            border-radius: 0.2rem;
                            padding: 0 1rem 0 0 !important;
                            margin: 0 !important;

                            // &:focus-within {
                            //     outline: 3px solid #0091D0;
                            // }

                            input[type="radio"] {
                                width: 1.25rem !important;
                                padding: 2em !important;
                                margin: 0 0 !important;
                                margin-right: 5px !important;
                            
                                border-radius: 100%;
                                display: inline-block;
                                position: relative;
                                

                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                &.error {
                    li {
                        input[type='radio'] {
                        // background: #F7D8D3;
                        outline: none;
                        // border: 1px solid red !important;
                        // border: 0.1rem solid #ffffff !important;
                        }
                    }
                }
                }
    
                &.error {
                    .error-message {
                        opacity: 1;
                        max-height: 3rem;
                    }
                }

                ul {
                    // display: flex;

                    li {
                        display: flex !important;
                        align-items: center;
                        margin: 0.25rem 0.5rem 0.25rem 0;
                        width: max-content;
                        width: auto;

                        span, label, input {
                            display: inline-block;
                            margin-right: 0.5rem;
                        }

                        span {
                            position: relative;
                            top: 0.05rem;
                        }

                        label {
                            &:focus-visible {
                                outline: none !important;
                            }
                        }

                        &:focus-within {
                            outline: 3px solid #0091D0 !important;
                            outline-offset: -3px;
                            border-radius: 0.2em;

                            input {
                                &:focus {
                                    outline: none !important;
                                }
                            }
                        }
                    }
                }
                }

                

                // ---- v Radio Button Check Box Sections v ------------------------------------------
                &.boxed {
                    // border: 0.1rem solid #f07c0f !important;
                    margin-top: 0.5rem;
                    padding: 0;

                    table {
                        display: flex;
                        flex-wrap: wrap;                       
                        tr {
                            width: 100%;
                            flex-basis: 100%;
                            display: flex;
                            flex-wrap: wrap;
                           

                            @include tablet-lg() {
                                flex-basis: 100%;
                            }

                            @include desktop() {
                                flex-basis: 100%;
                            }

                            td {
                                flex-basis: 50%;
                                // flex-grow: 1;

                                @include tablet-lg() {
                                    flex-basis: 50%;
                                }
                            }
                        }
                    }

                    .input-field {  
                        outline: 0.15rem solid #F1F1F1 !important;
                        flex-basis: 100%;
                        padding-bottom: 0em;
                        border-bottom: none;
                        position: relative;
                        height: 100%;
                        padding: 0rem !important;

                        p {
                            padding: 1rem 1rem 0 1rem !important;
                            
                        }
        
                        &:first-child {
                            border-top: none;
                        }

                        &.hidden-input-field {
                            // background: rgb(231, 224, 224);
        
                            label[for="Are_permits_required__c"] {
                            padding: 1rem 1rem 0 1rem !important;
                            }

                            .hidden-input {
                                // border: 1px solid orangered !important;
                                padding: 1rem 1rem 0 1rem;
                                margin-bottom: 0 !important;
                                label {
                                    padding-bottom: 1rem !important;
                                    display: block;
                                }

                                .hidden-input_inner  {
                                    border: none !important;

                                    .input-field {
                                        outline: none !important;
                                        margin-bottom: 0 !important;
                                    }
                                }
                            }
                        }
        
                        .error-message {
                            opacity: 0;
                            display: block;
                            font-weight: bold;
                        }
        
                        &.error {
                            color: #D4311E;
                            // outline: 0.15rem solid #D4311E !important;
                            z-index: 1;
                            
        
                            label {
                                color: #D4311E;
                            }
            
                            input {
                                background: #F7D8D3;
                                color: #D4311E;
                            }
            
                            .error-message {
                                opacity: 1;
                            }
                        }
        
                        label {
                            padding: 0 1rem !important;
                            margin-bottom: 0;
                            // border: 1px solid red;
                            padding-left: 1rem;
                            cursor: pointer;
                        }
                        
                        ul {
                            display: flex;
                            flex-direction: row;
                            padding: 0 !important;
                            justify-content: flex-start;
        
                            li {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin: 0 !important;
                                

                                label {
                                    // border: 1px solid rgba(255, 0, 0, 0.11);
                                    display: flex;
                                    align-items: center;
                                    border-radius: 0.2rem;
                                    padding: 0 1rem !important;
                                    margin: 0 !important;

                                    &:focus-within {
                                        // outline: 3px solid #0091D0;
                                    }

                                    input[type="radio"] {
                                        width: 1.25rem !important;
                                        padding: 2em !important;
                                        margin: 0 0 !important;
                                        border-radius: 100%;
                                        display: inline-block;
                                        margin-right: 0.5rem !important;
    
                                        &:focus {
                                            outline: none !important;
                                        }
                                    }
                                }
                            }
                        &.error {
                            li {
                                input[type='radio'] {
                                outline: none;
                                // border: 0.1rem solid #ffffff !important;
                                }
                            }
                        }
                        }
                    }
                
                }
            }

            .form-button {
                margin: 1rem 0;
                display: block;
                width: auto;
                max-width: max-content;
                font-size: 13px !important;
                border: none;
                background: #0086CA;
                color: white;
                font-weight: bold;
                text-transform: uppercase;
                padding: 1.25rem 2.25rem;
                border-radius: 0.1rem;
                cursor: pointer;
                transition: 0.1s;
                min-width: auto !important;
                display: flex;
                align-items: center;
                margin: 1rem 0 2rem 0;

                &:hover, &:focus {
                    background: #0086CA !important;
                }

                .form-button_icon {
                    margin-right: 0.5rem;
                    font-size: 1.25rem;
                }
            }

            p {
                padding: 0 0 0 0 !important;
                // margin:  0 0 !important;
            }

            .spinner {
                display: none;

                &.is-active {
                    display: block;
                }
            }

            .form-error {
                display: none;
                font-weight: bold;
                color: #D4311E;
                
                h2 {
                    padding-left: 0rem !important;
                }

                &.is-active {
                    display: block;
                }
            }
            .section.form-submit {
                    // border: 1px solid greenyellow;
                    margin-left: 0 !important;

                    .input-field-group.sign-and-date {
                        .input-field {
                            flex-basis: 100%;

                            @include tablet-lg() {
                                flex-basis: calc(50% - 0.5rem);
                            }
                        }
                    }

                    .submit-section {
                        padding: 0rem !important;
                        margin: 0rem !important;
                    }
            }
        
        }

    }



.row-global {
    display: flex;
    align-items: flex-end;
    padding: 0 0;
    justify-content: space-between!important;
    
    .column {
        display: block !important;
        flex-basis: 100% !important;
        padding: 0 !important;;

        @include tablet-lg() {
            flex-basis: calc(50% - 0.5rem) !important;
        }

        @include desktop() {
            flex-basis: calc(25% - 0.75rem) !important;
        }

        .row {
            .column {

            @include tablet-lg() {
            flex-basis: calc(50% - 0.5rem) !important;
        }
            }
        }
    }

    &.grow-3 {
        @include desktop() {
            justify-content: flex-start !important;

            .column {
                flex-basis: calc(33.33% - 0.75rem ) !important;
            }

            div:nth-child(3n + 2),  span:nth-child(3n + 2) {
                width: 33%;
                margin: 0 calc(3.25rem / 3 ) !important;
            }
        }
    }

    &.grow-4 {
        @include desktop() {
            justify-content: flex-start !important;

            .column {
                flex-basis: calc(25% - 1rem) !important;
            }

            div:nth-child(2) {
                width: 25%;
                // color: red;
                margin: 0 0.67rem 0 1.33rem;
            }

            div:nth-child(3n + 3) {
                    // color: blue;
                    margin: 0 0.67rem 0 1.33rem;
            }

            div:nth-child(4n + 3)  {
                // color: fuchsia;
                width: 25%;
                margin: 0 1.33rem 0 0.67rem;
            }
        }
    }


   
}

// LOC Application
#application-loc {
    .select-borrower {

        label {
            margin: 0.5rem 0 !important;
        }
    }

    .borrower-info-new {
        align-items: flex-start !important;

        .col-left {
            label {
                padding: 0.25rem 0rem !important;
            }
        }
        
        .col-left, .col-right {
            flex-basis: 100% !important;
            padding: 0 !important;
            margin: 0 !important;
            margin-bottom: 0 !important;
            margin-right: 0 !important;
            padding-bottom: 0 !important;
         
            @include tablet-lg() {
                flex-basis: calc(50% - 0.5rem) !important;
            }
              // states pick list
            .states-checkbox {
              
                width: 100%;

                .input-field {
                    margin-bottom: 1rem !important;
                    height: 248px !important;
                    overflow-y: hidden;
                    border: none !important;
                    // border: 1px solid rgb(81, 255, 0) !important;
                

                    label {
                        padding: 0.25rem 0rem !important;
                    }

                    ul {
                        height: 218px !important;
                        border: 0.1rem solid #F1F1F1;
                        // border: 1px solid rgb(0, 183, 255) !important;

                        li {
                            margin-right: 0 !important;
                            margin-left: 0 !important;
                            border-bottom: 1px solid #F1F1F1;
                            height: unset !important;
                        

                            &:last-child {
                                border-bottom: none !important;
                            }

                            &:focus-within {
                                // outline: 3px solid #0091D0 !important;
                                outline-offset: -3px;
                                border-radius: 0.2em;
                            }
                            
                            label {
                                padding: 0.155rem 1rem !important;
                                display: flex;
                                align-items: center;
                                // border: 1px solid rgb(105, 88, 88);
                                cursor: pointer !important;

                               input {
                                   height: unset !important;

                                   &:focus {
                                       outline: none !important;
                                   }
                               }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}