.credit-dates {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    flex: 1 !important;

    justify-content: flex-start;

    .input-field {
        flex-basis: 10%;
    }

    .divider {
        text-align: center;
        flex-basis: 5%;

        img {
            width: 4rem;
            margin-top: 2.5rem;
            margin-left: 1rem;
            opacity: 0.7;
        }
    }

    .ccv {
        margin-left: 2rem;
        flex-basis: 15%;
    }

    h1 {
        border-bottom: unset !important;
        margin-top: 2.5rem;
    }
}

.credit-numbers {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    flex: 1 !important;

    .input-field {
        flex-basis: 90%;
    }

    .credit-icon {
        flex-basis: 10%;

        img {
            width: 4rem;
            margin-top: 2.5rem;
            margin-left: 1rem;
            opacity: 0.7;
        }
    }
}