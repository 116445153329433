

// Global Styles - Tooltips (react module)
.__react_component_tooltip {
    color: white !important;
    background: #0F2A5B !important;
    padding: 1rem !important;
    border-radius: 0.5rem !important;
    transition: opacity 0.2s !important;
    z-index: 10000;
    border: 1px solid #466891 !important;
    box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.342);
    font-size: 1rem !important;
    // font-weight: bold;
}
.__react_component_tooltip.show {
    opacity: 1 !important; 
}

