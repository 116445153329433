@import "../../../../public/assets/css/variables";

@media screen and (max-width: 500px) {
    .flex-mobile-center {
        justify-content: center !important;
        width: 100% !important;

    }
}

.custom-scroll {
    border: unset !important;
    ul {
        height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid #F1F1F1;
        li {
            margin-right: 0 !important;
            padding-left: 0.5rem !important;
            border-bottom: 1px solid #F1F1F1;
            display: flex !important;
            align-items: center;
            padding: 0.5rem 0;
        }
    }
}

.additional-property {
    padding: 0.75rem 1rem 0.75rem 1rem;
    margin: 0 0 2rem 0 !important;
    display: block;
    align-items: center;
    line-height: 1.2;
    color: #0D224B;
    border: 1px solid #0D224B!important;
    border-top: none !important;
    transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    font-weight: bold;

    svg {
        margin-right: 0.25em;
    }

    &:hover {
        background-color: #0D224B;
        color: white;
    }
}


.row {

    width: 100%;

    &.property {
        padding-bottom: 1rem !important;
        position: relative;

        &.property-added {
            border-top: 2px solid #0D224B;
            padding-top: 1rem !important;   
    }
    .property-added_heading {
        font-weight: bold;
        background: #0D224B;
        color: white !important;
        
        margin: 0 !important;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        display: flex;
        align-items: center;

        button {
            background: white;
            font-size: 1.25rem;
            padding: 0.5rem 0.75rem;
            border-left: 1px solid #0D224B;
            border-right: 1px solid #0D224B;
            border-bottom: 1px solid #0D224B;
            border-bottom-left-radius: 0.25rem !important;
            transition: all 0.2s ease;

            &:hover {
                background: #0D224B;
                color: white;
            }
        }
        span {
            // background: red;
            padding: 0.5rem 1rem;
        }
    }
    }
}

.additional-property-wrapper {
    border-top: 2px solid #0D224B;
    padding-bottom: 00.5rem !important;
}

