@import '../../../css/variables.scss';

#deals-dashboard {
    .layout_base {
       
        @include tablet {
            border-top: 0rem;
        }

        @include desktop {
            border-top: 1rem !important;
        }
    }
}

.dialogue-box {
    .dialogue-box_content {
       .section-new#loc-required {
        button {
            color: #0091D0;
            margin: 0;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }
       }
    }
}


#third-party-form {
    margin-top: 0rem !important;
    h2 {
        text-transform: unset !important;
        color: #0D224B !important;
        border-bottom: none !important;
    }

    input {
        outline-offset: -3px;
    }

    .button-save {
        padding: 1rem;
        border-top-left-radius: 0.2em;
        border-top-right-radius: 0.2em;
        border: 0.1rem solid #0D224B;
        border-bottom: none;
        overflow: hidden;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.35rem !important;
        z-index: 100;
        position: relative;
    }

    .input-field {
        padding: 0 0 !important;
    }
}
