@import '../../../../../../assets/css/variables';

.marketing-materials {

  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .img-container {
    height: 64px;
    width: 120px;
    display: flex;
    margin: 1rem;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

.broker-resources {
  h3 {
    font-size: 1.5rem;
  }

  a {
    font-weight: bold;
  }
}