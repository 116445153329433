@import '../css/variables.scss';

html, body {
    background: #0c224B;
    background-image: linear-gradient(to bottom right, #0d2758, #0d2452, #0c224B, #0c224B, #0c224B, #0c224B);
    color: rgb(10, 10, 10);
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 10000;
    background-color: $modal-bg-white !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;

    .ReactModal__Content {
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.39);
        position: relative;
        left: unset;
        top: unset;
        margin-top: unset;
        margin-left: unset;
    }

    .ReactModal__Content > div {
        max-height: 50vh;
    }
}

.app-wrapper-new {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    strong {
        font-weight: bold;
    }

    &.session {
        margin: auto;
        max-width: 1500px;
        box-shadow: 4rem 4rem 4rem 12rem #0c224B;
        border-left: 0.1em solid rgba(255, 255, 255, 0.158);
    }
    

    * {
        box-sizing: border-box;
        line-height: 1.2;

        button {
            vertical-align: unset !important;
        }
      }
    
    p, a {
        font-size: 1rem;
        margin: 0;
        padding: 0;
    }

    button {
        font-size: 1rem;
    }

    a, button, input, select {
        &:focus {
            z-index: 1;
            outline: 3px solid #0091D0 !important;
            border-radius: 0.2em;
        }
    }

    a {
        color: #0091D0;
        text-decoration: none;
        
        &.is-active {
            text-shadow: 0.01rem 0.01rem 0.01rem #73A8CC;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    h1 {
        padding: 0;
        margin: 0;
        font-weight: 600;
        font-size: 1.75rem;
    }
 
     .content-wrapper-unprotected {
       padding: 5rem;
       width: calc(100% - 10rem);
     }

    .app-protected {
        display: block;
        position: relative;
        height: 100vh;
        width: 100vw;
        overflow-y: auto;
        padding: 0 !important;
      
    
        .form.loc-borrower {
          color: unset;
        
         p {
            color: unset !important;
            border-color: unset !important;
          }
        }
    
        @include tablet() {
            display: flex;
        }
    
        .app-protected_side {
          position: relative;
          overflow-y: auto;
    
            &.left {
                // background: #0F2A5B;
    
                @include tablet() {
                    flex-basis: 35%;
                    max-width: 22em;
                }
            }
    
            &.right {
              padding-top: 5rem;
              scroll-behavior: smooth;
            
                @include tablet() {
                    flex-basis: 65%;
                    flex-grow: 1;
                    padding-top: 0rem;
                    z-index: 1000;
                }
            }
        }
    
        .nav {
            position: fixed;
            right: 1rem;
            top: 1rem;
            width: auto;
            border: 1px solid black;

            button {
                padding: 1rem;
            }
          }
    }
  }