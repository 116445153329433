@import "../../../../assets/css/_variables";

#servicing-requests {

    ul {
        li {
            display: flex;
            align-items: center;
            margin: 0.5rem 0;

            img {
                width: 2rem;
                height: auto;
            }

            a {
                padding: 0.25rem 1rem;
            }
        }
    }

    // .form {
    //     h2  {
    //         text-transform: none !important;
    //         color: #0C224B;
    //         border-bottom: 2px solid #0C224B;
    //         padding: 1rem 0 0.5rem 0;
    //     }
        
    // }

    // .form.loc-borrower {
    //     padding: 0 !important;

    //     div {
    //         margin-left: 0 !important;
    //         margin-right: 0 !important;
    //     }

    //     p {
    //         margin-left: 0 !important;
    //         margin-right: 0 !important;
    //         padding-left: 0 !important;
    //         padding-right: 0 !important;
    //     }
    // }
}