@import '../../../css/variables.scss';

.section-new {
    padding: 1rem 0 0 0;
    // border-top: 1px solid #d87093;
    // border-bottom: 1px solid palevioletred;
    // border: 2px solid #fa6b6b2f;
    margin: 0;

    * {
        box-sizing: border-box;
    }

    .disabled {
        * {
            pointer-events: none;

            &:focus {
                outline: none !important;
            }

            &:focus-within {
                outline: none !important;
            }
        }
    }

    .error {
        margin: auto 1rem auto 0 !important;
    }


    // hwrapper for heading, controls, toggle
    .section_heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        //border-bottom: 2px solid #0D224B !important;
        // overflow: hidden;
        // background: lightcyan;

        // border-bottom-right-radius: 0.2em;

        // prevent text highlighting on click 
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.expandable {
            border-bottom-right-radius: 0.2em;
        }

        div, h2, button {
            height: 3rem;
            display: flex;
            align-items: center;
            color: #0D224B;
            border-bottom: none !important;
        }

      
        .heading {
            padding: 0 0;
            margin: 0 !important;
            font-weight: 600;
            font-size: 1.75rem !important;
            white-space: nowrap;
            overflow: hidden;
            position: relative;
            justify-content: space-between;
            width: 100%;
            
            // h2 {
            //     color: #10295B !important;
            // }
            // border: 0.1em solid greenyellow;
            // flex-grow: 1;

            &.is-clickable {
                cursor: pointer;
            }

            .mask {
                position: absolute;
                width: 1rem !important;
                right: 0;
                height: 100%;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.336), rgb(255, 255, 255), rgb(255, 255, 255));

                @include tablet-lg() {
                    display: none;
                }
            }
        }

        .controls-and-toggle {
            display: flex;
            justify-content: flex-end;
            // border: 0.1em solid rgb(255, 0, 179);
            button {
                font-size: 1.1rem;
            }

        .controls {
            margin-left: auto; // self-align right
            position: relative;
            // border: 0.1em solid rgb(0, 217, 255);
            // overflow: hidden;

            p {
                margin: 0 1rem;
            }

            &:focus-within {
                z-index: 1;
            }

            * {
                margin: 0 !important;
            }

            input, button {
                height: 3rem;

                &:focus {
                    z-index: 1;
                    outline: 3px solid #0091D0;
                    border-radius: 0.2em !important;
                }
            }

            button {
                &:hover {
                    color: #0091D0 !important;
                }
            }
        }

        .toggle {
            background: #10295B;
            color: white !important;
            border-top-left-radius: 0.2em;
            border-top-right-radius: 0.2em;
            overflow: hidden;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 1rem;

            span {
               width: 3rem;
            }

            &:focus {
                outline: 3px solid #0091D0;
                border-radius: 0.2em !important;
                z-index: 1;
            }
        }

    }

        // modifications to accomodate optional control element
        &.with-controls {
            flex-wrap: wrap;

            @include tablet() {
                // flex-wrap: nowrap;
            }

            @include tablet() {
                // flex-wrap: nowrap;
                border-bottom-left-radius: 0;
                border-bottom: 2px solid #10295B;
            }

            .heading {
                flex-basis: 68%;
                flex-grow: 1;
                // width: 100%;
                width: auto;

                @include tablet-lg() {
                    flex-basis: 50%;
                    width: auto;
                }
            }

            .controls-and-toggle {
                flex-grow: 1;
                flex-basis: 28%;
                // width: 100%;
          
                @include tablet-lg() {
                    // flex-grow: unset;
                    flex-basis: 48%;
                    // max-width: 30rem;
                }

                @include desktop() {
                    flex-basis: 30%;
                }

                @include desktop-xl() {
                    max-width: 30rem;
                }


            div.controls {
                width: 100%;
                justify-content: flex-end;
                border-top-left-radius: 0.2em !important;

                &.no-grow {
                    width: auto;
                }
             
              
                @include tablet-lg() {
                    order: unset;
                }

                button, input {
                    border-top: 0.1em solid #0D224B;
                    border-left: 0.1em solid #0D224B;
                    overflow: hidden;

                    &:first-child {
                         border-top-left-radius: 0.2em;
                    }
                }

                input[type=text] {
                    width: auto;
                    padding: 0 1rem;
                    flex-grow: 1;
                }
            }

            .toggle {
                border-top-left-radius: 0em;

                @include tablet() {
                    border-top-left-radius: 0em;
                }
            }
        }
    }
    }

    // main body element
    .section_content {
        overflow: hidden;
        padding: 1rem 0 1rem 0;
        position: relative;
        // border-bottom: 1px solid cyan;
        // border-top: 1px solid cyan;
        // background: pink !important;    
       
        span, p {
            margin: 0 !important;
            font-weight: normal;
            font-size: 1rem;
        }

        .content-hidden {
            color: unset;
            height: 100%;
            top: 0rem;
        }

        &.flush {
            padding: 0 0 1rem 0;

            .content-hidden {
                padding: 1rem 0 0 0;
            }
        }
    }

    .error-message {
        padding: 1rem 0;
    }

    // display errors
    span.dev-error {
        font-weight: unset !important;
        color: red;
        display: inline-block;

        &.spaced {
            margin: 1rem 1rem 1rem 0;
        }
    }
}