@import '../../../css/variables.scss';

.top-navigation {
    position: fixed;
    z-index: 1;
    border-top: 1px solid #919BB0;
    border-bottom: 1px solid #919BB0;
    width: 100%;
    //z-index: 1000;

    @include tablet() {
        position: absolute !important;
        border-top: 1px solid #0c224B;
        border-bottom: 1px solid #0c224B;
        position: relative;
        // height: 2.9rem;
    }

    @include tablet-lg() {
        position: relative !important;
        height: 3rem;
    }

    .background-navbar {
        height: 2.8rem;
        width: 100%;
        background: #0c224B;
        position: absolute;
    }

    .top-navigation_list {
         display: block;

        &.mobile {
            @include tablet-lg() {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @include tablet-lg() {
                display: flex;
            }
        }
       
    } 

    button {
        width: 100%;
        position: relative;
        background: #F1F1F1;
        display: flex;
        text-align: left;
        align-items: center;
        border-top: 1px solid white;
        border-bottom: 1px solid #919BB0;
        z-index: 1;
        padding: 1rem 2rem;
        height: 3rem;
        font-weight: bold;
        outline-offset: -3px;

        @include tablet() {
            border-top: none;
            background: #0c224B;
            border-bottom: 1px solid #0c224B;
        }

        span {
            display: block;
            position: relative;
            color: #0c224B;
            font-weight: normal;
            margin-right: 0.5rem;

            @include tablet() {
                color: white;
            }

           .breadcrumb {
               
                span {
                    display: inline;

                    &.root {
                        font-weight: bold !important;
                        }
                    }
                }

            svg {
                //   font-size: 1.25rem;
                  width: 1rem;
                  margin-right: 0.5rem;
            }
        }
    }

    ul {
        background-color: #F1F1F1;
        color: #0c224B;
        position: relative;
        top: 0px;
        border-bottom: 0.2rem solid #0c224B;;

        @include tablet() {
            background: #0c224B;
        }

        @include tablet-lg() {
            background: #0c224B;
            display: flex;
            top: unset;
            align-items: center;
            height: 2.8rem;
            padding: 0 1rem;
        }

        @include desktop() {
            padding: 0 3rem;
        }

        li {
            border-top: 1px solid #919BB0;
            border-left: none !important;
            border-bottom: none !important;
            align-items: center;
            transition-duration: 0s;
            justify-content: unset;
            padding-right: 0rem;
            padding: 0rem;

            @include tablet-lg() {
                border-top: none;
            }

            &.is-inactive {
                a {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            a {
                padding: 1rem 2rem 1rem 2rem !important;
                display: block;
                color: #0c224B;
                height: 3rem;
                position: relative;

                @include tablet() {
                    margin-right: 1rem;
                    padding: 1rem 1rem 1rem 2rem !important;
                    color: white;
                }

                @include tablet-lg() {
                    margin-right: 0;
                    padding: 1rem 1rem 1rem 1rem !important;
                    position: relative;
                    // top: 0.35rem;
                    height: 3rem;

                    &:after {
                        transition: .2s;
                        border-radius: 0.2rem;
                        display: block;
                        content: "";
                        width: 1rem;
                        height: 1rem;
                        background: white !important;
                        -webkit-transform: rotate(45deg) translateY(1rem) translateX(1rem);
                        transform: rotate(45deg) translateY(1rem) translateX(1rem);
                        position: absolute;
                        top: 2.5rem;
                        text-align: center;
                        opacity: 0;
                        left: calc(50% - 0.5rem);
                        z-index: 0;
                    }

                    &.is-active {
    
                        &:after {
                          opacity: 1;
                          top: 1.35rem;
                        }
                    }
                }

                @include desktop() {
                    padding: 1rem 1rem 1rem 1rem !important;
                }

                &:hover {
                    color: #0091D0;
                }

                &:focus {
                    outline-offset: -3px;
                }
            }
        }
    }

    .background {
        background-color: #f1f1f1b9;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        opacity: 0;
        pointer-events: none;

        &.is-active {
            opacity: 1;
            pointer-events: all;
        }

        @include tablet-lg() {
            display: none;
        }
    }
}

.top-navigation-spacer {
    height: 3.2rem;
    position: relative;
    background: white;

    @include tablet-lg() {
        display: none;
    }
}