@import '../../../../../css/variables.scss';

button.control-basic {
    // padding: 0 1rem;
    position: relative;
    overflow: hidden;
    flex-grow: 0;
    text-align: center;
    justify-content: center;

    // @include tablet() {
    //     width: 7rem;
    // }
  
    &:focus {
        outline: 0.2em solid #0091D0;
    }

    &:hover {
        div {
            // color: #0091D0 !important;
        }
    }

    &.is-disabled {
        pointer-events: none;
    }
 
    &:disabled {
        svg, div {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    
    div {
        font-weight: bold;
        width: 100%;
        height: 3.2rem !important;
        position: relative;
        top: -0.1rem;
        width: 3rem;
        text-align: center;
        display: flex;
        justify-content: center;
       
        span.copy-button-text {
            padding-left: 0.5rem !important;
            display: none;

            // @include tablet() {
            //     display: unset;
            // }
        }
    }
}