@import '../../../../assets/css/variables';
.create-account-rf {

  h3 {
    color: #202E59 !important;
    font-size: 1.4rem;
    margin: 0 0 4rem 0 !important;
  }

  form {
    max-width: 30rem;
    margin: auto;

    button {
      margin: 4rem auto 0 auto;
    }
  }

  a {
    font-size: 1.4rem;
    // font-weight: bold;
    text-decoration: underline;
    color: #0086CA !important;

    &:hover {
      color: #6FADD5 !important;
    }
  }
}