.form-loss-draft {

    p {
        padding: 0 !important;
       color: unset !important;
    }

  

    textarea {
        background-color: #f1f1f1;
        padding: 1rem;
        width: 100%;
        height: 3.5rem;
    }

    .input-field {
        height: 6rem;
        
        label {
            color: black !important;
        }
    }

    span.error-message {
        display: block;
        margin: 0.5rem 0 !important;
    }
    .bold {
        font-weight: bold;
    }
}