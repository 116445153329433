.payment-history_year {

    .no-results {
        padding: 0.5rem 1rem;
        display: block;
        border: 1px solid #F1F1F1;
        border-radius: 0.15rem;
    }

    .payment-history_year_heading {
        font-size: 1rem;
        background-color: #0D224B !important;
        color: white !important;
        margin: 0 !important;
        border-radius: 0.15rem;
        padding: 0.5rem 1rem;
    }
    margin-top: 2px !important;

    button {
        background: #0D224B;
        color: black;
        // border-top: 2px solid #0D224B;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        border-radius: 0.15rem;
        font-weight: bold !important;
        margin: 0 !important;

        svg {
            position: relative;
        }

        &:hover{
            color: #3D91CB;
        }

        &:focus {
            color: #3D91CB;
            outline: 2px solid #3D91CB;
            outline-offset: -2px;
        }

        .year {
           font-weight: bold;
        }

       
    }

    .content {
        li:last-of-type button {
            border-bottom: none;
        }
    }
}