@import '../../../../css/variables.scss';

#dashboard_loans-funded {

    .section_content {
        padding-top: 0;

        .content-hidden {
            padding: 1rem 0;
        }
    }

    // expand table sub rows toggle in controls
    button.button-rows-expand {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 1rem;
        color: #0c224b;

        svg {
            width: 1rem;
        }
    }
   
    tr {
        th {
            span {
                font-weight: bold;
            }
        }

        // scaling visible table cell data with increasing screen size
        th,td {
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                display: none;
            }
        }

        @include tablet-lg() {
            th,td {
                &:nth-of-type(5) {
                    display: table-cell;
                }
            }
        }

        @include desktop() {
            th,td {
                &:nth-of-type(3)
                {
                    display: table-cell;
                }
            }
        }

        @include desktop-xl() {
            th,td {
                &:nth-of-type(8),
                &:nth-of-type(4),
                &:nth-of-type(7) {
                    display: table-cell;
                }
            }
        }
    }
}