li.payment-history-entry {
    &:nth-child(even) {
        background: #63aad00e;
    }

    .payment-history-entry_heading {
        display: flex;
        background: none;
        align-items: center;
        margin: 0;
        cursor: pointer;
        position: relative;
        border: 0.15em solid #F1F1F1;
        // font-style: italic;
        font-weight: normal !important;
       

        // &:last-of-type {
        //     border-bottom: none;
        // }
            padding: 0;
        

        &:hover {
            color: #3D91CB;
        }

        &:focus {
            color: #3D91CB;
            outline: 2px solid #3D91CB;
            outline-offset: -2px;
        }

        span {
            // flex-basis: calc(33% - 1.25em);
            flex-basis: 33.33%;
            // padding: 0.5em 1em 0.5em 0.25em;
            padding: 0.5rem 1rem;
            font-weight: bold !important;
        }

        svg {
            margin-right: 0.8rem;
        }
    }

    .data {
        .data_inner {
            table {
                width: 100%;

                tbody {
                    width: 100%;
                    display: block;
                    
                tr {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    // padding: 0.15rem 0.5rem;
                    border-bottom: 0.15rem solid #F1F1F1;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    th {
                        // flex-basis: calc(66.66% - 1.25em);
                        // padding: 0.5em 1em 0.5em 0.25em;
                        flex-basis: 66.66%;
                        // padding: 0 0.25rem;
                    }

                    td {
                        // flex-basis: calc(33.33% - 1.25em);
                        flex-basis: 33.33%;
                        // padding: 0.25rem 0rem 0.25rem 0rem;
                        border: none;
                    }
                }
            }
            }
        }
    }
 }