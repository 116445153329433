// Summary - Access for unprotected legacy layouts with US-49 style updates (new version)

// Description - Wrapper for all of the general styles associated.  
// Imported into main style file as this is a strictly CSS variant of the parent styles

.layout_account-access {

    background-color: #F1F1F1;
    font-family: proxima-nova, sans-serif;
    
    article {
        min-height: 80vh;
    }

    h1 {
        color: #202E59;
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 700;
        border-bottom: unset;
        font-size: clamp(32px, 3vw, 5.1rem);
        margin: 2rem auto 2rem auto !important;
    }

    form {
        max-width: 30rem;
        margin: auto;

        label {
            color: #202E59 !important;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
            text-align: left !important;
            padding: 0.5rem 0 !important;
            margin: 0;
            display: block;
        }

        input {
            background-color: #F1F1F1;
            color: #202E59 !important;
            padding: 0.25rem 0;
            text-align: left !important;
            border-bottom: 0.15rem solid grey;
            margin: 0 0 2rem 0;
            filter: none !important; // remove FF yellow autofill background
        }
    }

    a {
        text-decoration: none;
        color: #0086CA !important;
        font-family: 'Proxima Nova', sans-serif;
        font-size: 1.25rem;
        text-decoration: underline;

        &:hover {
            color: #6FADD5 !important;
        }
    }

    button {
        background-color: #182850;
        margin: 4rem auto 1rem auto;
        // width: 100%;
    }

    p {
        margin: 1rem 0 !important;
        text-decoration: none;
        // color: white !important;
        font-family: 'Proxima Nova', sans-serif;
        font-size: 1.5rem !important;
    }

    // Variant Styles

    // Centered
    &.centered {
        article {
            // height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6rem 2rem;

             * {
                text-align: center;
             }

             .input-field {
                text-align: left;
            }
        }
    }
}