// @import "../../../../../public/assets/css/variables";
@import '../../../../../css/variables.scss';

.document-upload-modal {
  max-width: 500px !important;
  justify-content: center;

  fieldset {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.upload-files-table {
  width: 100%;

  tr {
    line-height: 2.5rem;
    vertical-align: middle;

    &:first-child {
      border-bottom: none !important;
    }

    &:nth-child(n + 1) {
      border-bottom: 1px solid $another-grey;
    }

    &:last-child {
      border-bottom: none !important;
    }

    td {
      padding-left: 1.5rem;
    }
  }
}

.file-upload {
  .hovered {
    svg:first-child {
      font-size: 5rem !important;
    }
  }

  @media screen and (max-width: 600px) {
    .button-submit-rf {
      width: 100% !important;
    }
  }


  .heading {
    text-align: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 2px dashed $another-grey;
    transform: translateY(-4rem);
    transition: all 0.5s ease;
    
    @media screen and (max-width: 600px) {
      width: 100%;

      .hide-mobile {
        display: none;
      }
    }

    div:first-child {
      padding: 1rem;
    }

    svg:first-child {
      font-size: 3rem;
      color: $dark_blue;
      left: 0.1rem;
      transition: all 0.5s ease;
    }

    p:nth-child(2) {
      margin-top: 1rem !important;
      color: $medium_blue;
    }

    p:nth-child(3) {
      margin-top: -1rem !important;
      color: $medium_blue;
    }

    button {
      margin-top: -1rem;
      background-color: $dark_blue;
      color: white;
      padding: 1rem;
      border-radius: 0.5rem;
      -webkit-box-shadow: 0px 3px 10px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow: 0px 3px 10px 6px #ccc; /* Firefox 3.5 - 3.6 */
      box-shadow: 0px 3px 10px 6px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    }
  }

  label {
    color: $darker-grey;
  }
}

.file-preview-modal {
  position: absolute;
  width: 70vw;
  height: 90vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: calc(286px / 2);

  iframe {
      display: block; 
      width: 100%; 
      height: 100%; 
      border: none;
  }
}