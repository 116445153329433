.input-field-rad-check_inner label {
  display: flex !important;
  align-items: center;
}

.gaps .input-field {
  flex-basis: 48% !important;
}

.form .form-body .input-field-group div label.m-3px {
  margin-left: -3px !important;
}

.form .form-body .input-field-group.flex-start {
  align-items: flex-start !important;
  flex-direction: column !important;
}

.form {
   line-height: 1.2;

    &.main {
        .input-field {
            margin-bottom: 1rem;
        }

      h2 {
        margin: 0 !important;
      }

        .input-field-rad-check {
            padding: 1rem;
        
            .input-field-rad-check_inner {
                display: flex;
                align-items: center;
        
                input {
                    margin: 0rem 1rem;
                    display: block;
        
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    h2 {
        padding: 1rem 0 0.5rem 0;
        margin: 1rem;
        border-bottom: 1px solid black;
    }

    p {
        padding: 0 1rem 1rem 1rem !important;
        margin-top: 0rem !important;
        font-weight: bold;
    }
}