@import "../../../../../../css/variables.scss";

.loc-doc-upload-modal {

    .document-uploads {
        margin: 0 !important;
    }

    .document-upload {
        flex-basis: 100%;
        text-align: left;
        margin: 0em 0 2em 0;
    }

    .document-upload .rentrollupload {
        height: 50px !important;
    }

    .property-box-generic_inner {
        flex-basis: 100%;
    }

    .rentrollupload p {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 50px;
        font-family: Arial;
    }

    .button-submit {
        background: #3784C4;
    }

    .property-box-generic {
        margin-bottom: 0rem !important;
    }

    .top-nav {
        background: #1D3B62;

        a {
            color: white;
            padding: 1rem 2rem;
            display: block;
            font-weight: normal;

            &:hover {
                color: white;
                text-decoration: underline;
            }

            &.is-active {
                text-decoration: underline !important;
                text-shadow: 0.2px 0.2px white;
            }
        }
        
        @include tablet {
        background: #1D3B62;
        padding: 1rem 5rem;
        display: flex;

        .construction {
            opacity: 1;
            pointer-events: all;
            transition: 0.2s;
            font-weight: normal !important;

            &.is-inactive {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        a {
            padding: 0.5rem 3rem 0.5rem 0;
            color: white;
            position: relative;
            display: flex;
            justify-content: center;
            transition: 0.2s;
            white-space: nowrap;

            &:after {
                transition: 0.2s;
                display: block;
                content: '';
                width: 1rem;
                height: 1rem;
                background: white;
                transform: rotate(45deg) translateY(1rem) translateX(1rem);
                position: absolute;
                top: 2.5rem;
                opacity: 0;
            }

            &:hover {
                color: white;
                text-decoration: none;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) translateY(0rem);
                }
            }

            &.is-active {
                text-shadow: 0.2px 0.2px white;
                text-decoration: none;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) translateY(0rem);
                }
            }
        }
    }
    }
    
    .deal_inner {
        padding: 3rem 5rem;

        .deal-header {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            .deal-header_inner {
                display: flex;
                flex-direction: column;

                @include tablet-lg {
                    flex-direction: row;
                }

                .property-box-generic {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }

            .loader  {
                position: absolute;
                top: 0;
                left: 0;
                background-image: linear-gradient(#ffffff, rgb(255, 255, 255));
                height: 100%;
                width: 100%;
                z-index: 1000;
                opacity: 1;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding: 6rem 0;
    
                &.is-hidden {
                    opacity: 0;
                    transition-delay: 0.2s;
                    transition-duration: 0.6s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    pointer-events: none;
                }
            }
        }

        
    }
}