.broker-terms {
  box-sizing: border-box;
  // padding: 1rem;
  width: 100%;

  span {
    font-weight: normal !important;
  }

  strong {
    font-weight: bold;
    width: 100%;
    display: inline;
  }

  h2 {
    strong {
      display: inline-block;
      width: 100%;
    }
  }

  .centered {
    text-align: center !important;
    width: 100% !important;
  }

  .caps {
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
  }

  p {
    padding: 1rem 0;


  }

  ol {
    list-style: decimal;
    padding: 1rem 1rem 0 1rem;

    &.alpha {

      list-style: upper-alpha;
      padding-left: 1rem;

    }

    li {
      padding: 1rem 0;
    }
  }

  ul {
    padding-left: 2rem;
    padding-right: 1rem;
    list-style: unset;
    margin: 1rem 0 2rem 0;

    li {
      padding: 0.5rem 0;
    }
  }
}