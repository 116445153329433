@import '../../../../../../css/variables.scss';

.construction-budget-upload {
    input {
      border: 2px solid #040505;
      padding: 1.25rem 2.25rem;
      border-radius: .15rem;
      cursor: pointer;
      transition: .1s;
      display: block;
      width: auto;
      font-weight: bolder;
      // text-transform: uppercase;
      font-size: 13px;
    }

    .upload-message {
      font-weight: bolder;

      &.error {
        color: #D4311E;
      }
    }
}