   @import '../../../../../assets/css/variables';

// section - action items 
#action-items {
   .heading {
        svg {
            color: #DC7D30;
            margin-right: 0.5rem;
        }
   }

}