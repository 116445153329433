// Default Body Error Message 
.error-message {
    font-size: 1.25rem;
    padding: 1rem 0 !important;
    display: inline-block;
    font-weight: normal !important;

    button {
        font-size: inherit;
        vertical-align: unset;
        font-weight: bold;
        color: #0091D0;

        &:hover {
            text-decoration: underline;
        }
    }
}
