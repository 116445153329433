@import '../../../../../assets/css/variables';

.broker-dashboard {
    .layout_base {
       
        @include tablet {
            border-top: 0rem;
        }

        @include desktop {
            border-top: 1rem !important;
        }
    }

    .top-nav {
        overflow: hidden;
        background: #1D3B62;
        padding: 1rem 5rem;
        display: flex;

        .construction {
            opacity: 1;
            pointer-events: all;
            transition: 0.2s;
            font-weight: normal !important;

            &.is-inactive {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        a {
            padding: 0.5rem 3rem 0.5rem 0;
            color: white;
            position: relative;
            display: flex;
            justify-content: center;
            transition: 0.2s;
            

            &:after {
                transition: 0.2s;
                display: block;
                content: '';
                width: 1rem;
                height: 1rem;
                background: white;
                transform: rotate(45deg) translateY(1rem) translateX(1rem);
                position: absolute;
                top: 2.5rem;
                opacity: 0;
            }

            &:hover {
                color: white;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) translateY(0rem);
                }
            }

            &.is-active {
                text-shadow: 0.2px 0.2px white;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) translateY(0rem);
                }
            }
        }
    }
}