.dialogue-box {
    h3 {
        padding: 0 0;
        margin: 0 !important;
        font-weight: 600;
        font-size: 1.75rem !important;
        border-bottom: 2px solid #112347;
        height: 3rem;
        display: flex;
        align-items: center;
    }
}