.form {
    &.file-upload {
        
        box-sizing: border-box;
        padding: 0 !important;
        margin: 0 !important;
        
        fieldset {
            position: relative;
            max-width: 20em;
            margin: 1rem 0;
            
            input {
                opacity: 0;
                height: unset !important;
                cursor: pointer !important;
                padding: 0.5rem 1rem;
                max-width: 18em;
            }

          .button {
            //   border: 1px solid rgb(236, 191, 191);
            //   background: grey;
              display: flex;
              position: absolute;
              top: 0;
              left: 0;
            //   padding: 0.5rem 1rem;

            label {
                cursor: pointer !important;
                background: #0F1F42;
                color: white;
                font-weight: bold;
                border-radius: .15rem;
                margin: 0;
                padding: 0;
                // margin-right: 1rem;
                border: 0.15rem solid white;
                padding: 0.5rem 1rem;

                &:hover {
                    opacity: 0.9;
                }
            
                &:focus {
                    outline: 0.35rem solid #0091d0 !important;
                    transition: unset !important;
                }
            }

            .file-name {
                pointer-events: none;
                padding: 0.5rem 1rem;
                font-weight: normal !important;
            }
          }
        }
    }
}
