@import '../../../../../assets/css/variables';

.privacy-conditions-wrapper {
    .terms-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0 auto;
        background-color: #000000d2;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: 0.1s ease;
        z-index: 999999;
    
        &.is-active {
            opacity: 1;
            pointer-events: all;
        }

        .terms-inner {
            background-color: #fff;
            color: black;
            max-width: 900px;
            max-height: 70%;
            width: 70%;
            overflow: scroll;
            padding: 6rem 3rem 6rem 3rem !important;
            position: relative !important;
            text-align: justify;

            .section {
                padding: 0rem 0 !important;
            }

            p {
                padding: 0 1rem 1rem 1rem !important;
                margin-top: 0rem !important;
                font-weight: bold !important;
                font-size: 1em !important;
            }

            h1 {
                border-bottom: 2px solid #000000 !important;
                padding-bottom: .9rem !important;
                color: #0c224b !important;
                padding-bottom: .5rem !important;
                margin-bottom: 1rem !important;
                font-weight: 600 !important;
                font-size: 1.75rem !important;
                font-family: unset !important;
            }

            h4 {
                padding-bottom: .9rem !important;
                color: #0c224b !important;
                padding-bottom: .5rem !important;
                margin-bottom: 1rem !important;
                font-weight: 600 !important;
                font-size: 1.25rem !important;
                font-family: unset !important;
                text-decoration: underline;
            }

            @include tablet {
            width: 60%;
            height: auto;
        }

        .close {
            display: block;
            position: absolute !important;
            top: 0;
            right: 0;
            width: 100%;
            // text-align: right;
            
           
           .terms-close {
               font-size: 2rem;
               display: block;
                padding: 3rem;
                position: absolute;
                right: 0;
               color: black !important;

           }
        }

        .terms-inner_body {
        pointer-events: none;

            h1 {
                border-bottom: 2px solid #000000;
                padding-bottom: .9rem;
            }
            
            p {
                padding: .5rem 0;
                font-size: 0.8125rem;
            }
        }
    } 
    }
}