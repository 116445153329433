@import '../../../../../../../../css/variables.scss';

.draw-request-form {
* {
    box-sizing: border-box;
}

p {
    margin: 0 0 1rem 0;
}

button {
    pointer-events: all;
    cursor: pointer;
    padding: 1rem;
    text-transform: uppercase;



    &:focus, &:hover {
        outline: .15rem solid #0086CA;
    }

    &.button-add-line-item {
        padding: 0.5rem 1rem 0.5rem 0;
        text-transform: none;
        color: #0086CA;

        &:hover {
            color: #0D234A;
        }
    }
}

table {

    .delete-line-item_icon {
        font-size: 18px;
        position: relative;
        top: 0.25rem
    }

    thead {
        th {
            // text-align: left;
            border: 1px solid #F3F3F3;
            text-transform: uppercase;
            display: table-cell;
            background-color: #F3F3F3;

            div {
                display: flex;
                align-items: center;
                padding: 1rem 1rem 1rem 1rem;
            }
        }
    }

    tbody.draw-table-body {
    tr.line-item {

        &:focus-within {
            outline: none;
        }
        td {
            padding: 0 !important;
            display: table-cell;
            border: 0.15rem solid #F3F3F3;

            span {
                padding: 0 !important;
            }

            &:first-of-type {
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .input-field {
                display: flex;
                align-items: center;
                justify-content: left;
                padding: 0;
            
                &.data-only {
                    pointer-events: none;
                    // color: red;
                }

                &.no-input {
                   input {
                    // pointer-events: none;
                    // opacity: 0.5;
                   }
                }
            
                &.error {
                    input {
                        outline: .15rem solid #D4311E;
                        background: #F3F3F3;
                    }
                }

                    label {
                        opacity: 0;
                        position: absolute;
                        pointer-events: none !important;
                    }
        
                    input {
                        padding: 0.5rem 1rem;
                        // height: 2rem;

                        &:focus {
                            outline: .15rem solid #0086CA;
                            position: relative;
                            z-index: 100;
                        }
                    }
                }
            }
        }
        .sublot-title {
            font-weight: bold;
        }
        &:nth-of-type(even){
            background-color: #f1f1f1;
                border-color: #e1e1e1;
        
        }
        
        &:nth-of-type(even) tr,
        &:nth-of-type(even) tr td{
            border-color: #e1e1e1;
        }
    }
}

.line-item-status {
    padding: 1rem 0;
    color: #D4311E;
    font-size: 13px;
    display: none;
    font-weight: bold;

    &.error {
        display: block;
    }
}

    .submit-wrapper {
        // border: 2px solid #0C224B;
        background: #0D224B;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
        border-bottom-left-radius: 0.15rem;
        border-radius: 0.15rem !important;
        border-bottom-right-radius: 0.15rem;
        overflow: hidden;
        margin: 2px 0 1rem 0;

        .total-label {
            padding: 0 1rem;
            // flex-basis: 100%;
            
            font-weight: normal;

            span {
                font-weight: bold;
            }
        }

        .total-amount {
            padding: 0rem 2rem;
        }

        button {
            background: #07804D;
            border: 2px solid #07804D;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            border-bottom-right-radius: 0.15rem;
            transition: 0.1s;
            padding: 0.5rem 1rem;

            &:focus {
                outline-offset: -3px;
            }

            &:hover {
                border-color: #0D224B;
                color: #0D224B;
                background-color: white;
                outline: none;
            }

            &:disabled {
                background: gray;
                color: white !important;
                border: 1px solid gray !important;
                cursor: not-allowed !important;
            }
        }
    } 
}




