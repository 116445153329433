.card {
    display: flex;
    align-items: center;
    padding-right: 2rem;
  
    .card-image {
        height: 6rem;   
        width: 6rem;
        background: #F1F1F1;
        border-radius: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;
        overflow: hidden;

        svg {
            color: white;
            font-size: 3rem;
        }

        img {
             border: 1ps solid hotpink;
             position: absolute;
             height: 6rem;
             width: auto;
             top: 0rem;
             left: 0;
        }
    }

    span, a {
        display: block;
        padding: 0.05rem 0;
    }

    span {
        font-weight: bold !important;
        color: #0D224B;
    }

    a {
        text-decoration: none;
    }
}