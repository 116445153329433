.faq {

  .faq-heading {
    margin-top: 1rem;
    background-color: #EEEEEE;
    font-size: 1em;
    padding: 1rem 2rem;
    width: 100%;
    text-align: left;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #444 !important;
      font-size: 15px;
    }
  
    &:hover, &.is-active {
      background-color: #ccc;
    }

    &:focus {
      outline-offset: -3px !important;
    }
  }
  
  .faq-content {
    background-color: #EEEEEE;
    font-size: inherit;
    padding: 1rem 2rem;
    margin-bottom: 1rem;

    p {
      margin: 1rem 0 !important;
      font-size: 1em !important;
      font-size: 13px !important;

      a {
        text-decoration: underline;
        color: #0c224a;
      }
    }
  }
}