@import '../../../../assets/css/variables';


.slideUp {
    top: 5vh !important;
}

.signup-rf.account-setup {
    z-index: 3;
    position: absolute;
    max-width: 1440px;
    margin: auto;
    background-color: #F1F1F1;
    top: 140vh;
    height: 100%;
    transition: top 0.5s ease-in-out;
    // border: 1px solid red;
    width: 80%;

    .error {
        color: red !important;
    }

    .one {
        background: none;
        flex-basis: 40%;
        padding: 6rem 0rem 0 0rem;
        display: block;

        @include tablet-lg {
            // padding: 6rem 6rem;
            padding: 6rem 4rem 4rem 0;
        }
    }

    .two {
        flex-basis: 60%;
        padding: 2rem 0rem 6rem 0rem;

        @include tablet-lg {
            padding: 8rem 4rem;
        }

        button {
            margin: 3rem auto 0 auto;

            @include tablet-lg {
                margin: 4rem 0 0 0;
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .height-adjustment {
        padding-bottom: 14rem;
    }

    .signup-rf.account-setup {
        width: 100% !important;
        padding: 1rem !important;
    }
}

.first-prompt {
    width: 100%;
    height: calc(100vh - 12rem);
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;



    .flex-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        button {
            margin: 4rem !important;
            font-weight: bold;
            font-size: 1.5rem;
            color: white;
            background-color: #0070C0;
            padding: 1rem;
            border: 3px solid #03538d;
            border-radius: 0.5rem;
        }
    }

    h5, h1 {
        color: #0070C0 !important;
        margin: 1rem !important;
    }

    h1 {
        font-size: 4rem;
        font-weight: bold;
    }

    h5 {
        font-size: 1.5rem;
    }


    .colors-black {
        color: black !important;
    }

    ul {
        position: relative;

        .verticalLine {
            position: absolute;
            top: 0;
            left: 4rem;
            height: 70%;
            margin-top: 2.2rem;
            border-left: 1px solid gray;
            z-index: 1;

            @media screen and (max-width: 600px) {
                height: 60%;
                margin-top: 3rem;
            }
        }

        //list-style-image: url("/assets/images/bullet.png");
        li {
            display: flex;
            align-items: center;
            margin: 2rem;
            gap: 2rem;
            font-size: 1.5rem;
            color: #0070C0 !important;
            z-index: 5;

            img {
                width: 4rem;
                height: 4rem;
                z-index: 2;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .first-prompt {
        h1 {
            font-size: 3rem !important;
        }

        h5 {
            font-size: 1.2rem !important;
        }

        li {
            font-size: 1.2rem !important;
        }
    }

    .signup-rf.account-setup {
        top: 200vh;
    }

    .slideUp {
        top: -12vh !important;
    }
}