// @import "../../../../assets/css/variables";
// @import "../../../CollapseSection/index.scss";

@import '../../../../../css/variables.scss';

.locTable {
  .comment-box-label {
    font-size: 0.8rem !important;
    padding: unset !important;
    line-height: 1rem !important;
    margin: unset !important;
  }

  table-layout: fixed;

  .normal {
    font-weight: normal !important;
  }

  @media screen and (max-width: 600px) {
    .hide-mobile {
      display: none;
    }

    .hide-mobile1 {
      width: 20% !important;
    }

    .line-credit {
      padding-left: unset !important;
    }
  }

  tr {
    border-bottom: 1px solid lightgray !important;
    vertical-align: middle;
    &.checklist {
      display: none;
      padding: 0rem 0 !important;
      &.opened {
        display: table-row;
        td {
          padding: unset !important;
          padding-bottom: 1rem !important;
          padding-top: 1rem !important;
          vertical-align: middle;

          td {
            padding: 0.5rem !important;
          }

          .green {
            background-color: green;
          }

          .upload-files {
            padding-left: 0.5rem;
          }

          .button-submit {
            @media screen and (max-width: 1040px) {
              width: 100% !important;
              padding: unset !important;
            }
          }

          span {
            font-size: 0.8rem;
          }

          textarea {
            background-color: $light-grey;
            border: 1px solid $another-grey-two;
            width: 95%;
            max-width: 95%;
            min-width: 95%;
            min-height: 40px;
          }
        }
      }
    }
  }

  svg {
    cursor: pointer;
    transition: 0.1s;

    &.opened {
      transform: scale(-1);
    }
  }

  .checklist {
    border-bottom: none !important;
  }

  tr.checklist.opened {
    &:focus-within {
      outline: none !important;
    }

    &:hover {
      background-color: unset;
    }
      
    td {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      
     
      tr {
        &:focus-within {
          outline: none !important;
        }

        // actual table cells :)
        td {
          // padding: 0.5rem 0 0.5rem 1rem !important;

          p {
            display: block;
            margin-bottom: 0.5rem !important;
          }
         
          textarea, button {
            &:focus {
              outline: 3px solid #0091D0;
              outline-offset: -3px;
            }
          }

          textarea {
            border-radius: 0.2em !important;
            max-width: unset;
            min-width: unset;
            width: 100%;
            background-color: white;
          }

          button {
            margin: 0 !important;
            height: 100%;
          }
        }
      }

      textarea {
        padding: 0.5rem;
      }

      button {
        background-color: white !important;
        color: #0D224B;
        height: unset !important;
        padding: 1rem !important;
        display: flex;
        align-items: center;
        width: 100%;

        svg {
          margin-right: 0.5rem;
        }

        span {
          padding: 0 !important;
        }
      }
    }
  }

  &.desktop-view {
    // background-color: lime !important;
 }
}

.loc-doc-upload-modal {
  width: 100%;
  max-width: 620px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -200px;
  background-color: $white;
  padding: 2rem;
  outline: none;
  box-sizing: border-box;
  z-index: 2;

  @media screen and (max-width: 600px) {
    width: 100vw !important;
    height: calc(100vh - 5rem) !important;
    top: unset;
    left: unset;
    margin-top: 5rem;
    margin-left: unset;
  }
}
