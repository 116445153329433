@import '../../../../../assets/css/variables';

.footer-primary {
    padding: 30px 0;
    background: white;
    border-top: 1px solid #C2DDE7;

    .footer-primary_inner {
        margin: auto;
        width: 80%;
        max-width: 1440px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include phone-med {
            justify-content: space-between;
        }

        div {
            flex-basis: 50%;
            
            @include phone-med {
                flex-basis: unset;
            }

            &:first-of-type {
                text-align: center;
                flex-basis: 100%;

                img {
                    width: 16rem;

                    @include tablet {
                        width: 12em;
                    }
                }

                @include tablet {
                    flex-basis: unset;
                    text-align: left;
                }
            }
        }

        div.footer-widget {
            margin-bottom: 2rem;

            h3 {
                margin: 2rem 0 1rem 0 !important;
                font-size: 14px !important;
                line-height: 16px;
                
                color: #1E2D5C;
                font-family: proxima-nova, sans-serif;
            }

            p {
                font-size: 14px !important;
                line-height: 16px;
                color: black !important;
            }

            ul {
                li {

                    a {
                        font-size: 14px;
                        color: #0091D0 !important;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }

            // Left with logo and contact info
            &:first-of-type {
                ul {
                    li {
                        a {
                            font-weight: normal;
                        }

                        
                    }

                    // lima contact
                    &:first-of-type {
                            li {
                                a {
                                    color: #0086CA !important;

                                    svg {
                                        margin-right: 0.5rem;
                                        &:first-of-type {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                    }

                    &:last-of-type {
                        margin-top: 0.5rem;
                        
                        li {
                            display: inline-block;

                            a {
                                padding: 0.5rem 1rem 0.5rem 0;
                                font-size: 1.5rem !important;
                                color: #005194 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}