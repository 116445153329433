@import '../../../../../../css/variables.scss';

#deal-header.section-new.expandable {

    .deal-status-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 0 0 0;
        border: 1px solid #F1F1F1;
        max-height: 406px;

        @include desktop() {
            padding: 0;
        }

        .deal-status {
            width: 100%;
            padding: 2rem 0 0 0;
            overflow: hidden;

            @include desktop() {
                padding: 1rem 0 0 0;
            }

            p, span {       
                @include tablet-lg {
                    font-size: 1.25rem;
                }
            }

            .status-container {
                align-items: flex-start;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-center;
                padding: 1rem 0.5rem;

                .deal-status-item {
                    flex-basis: 33.33%;
                    text-align: center;
                    padding: 1rem 0.5rem;

                    p {
                        padding: 0 0 0 1rem 0;
                        
                        @include tablet-lg() {
                            font-size: 1.15rem;
                        }
                    }

                    span {
                        color: #83838F;
                        padding: 1rem 0;
                        display: block;
                    }

                    svg {
                        display: inline-block;
                        border-radius: 100%;
                        width: 3rem;
                        height: 3rem;
                        box-shadow: 0px 0px 2px #888;
                        color: white;
                        padding: 0.7em 0.7em;
                        font-size: 1rem;
                        margin-bottom: 0.5rem;
                    }

                    .ordered {
                        background-color: darkgray;
                    }

                    .needed {
                        background-color: darkorange;
                    }

                    .completed {
                        background-color: green;
                    }

                    .danger {
                        background-color: orangered;
                    }
                }
            }

            .deal-details_panel {
                span {
                    padding: 1rem 0;
                }

                padding: 0rem 0.5rem 0.5rem 0.5rem;

                // Colours -----------------------------------------
                $grey:  #777;
                $grey2: #dfe3e4;
                $blue:  #2183dd;
                $green: #009900;
                $white: #fff;

                // Progress Bar ------------------------------------
                .progress {
                position: relative;
                display: flex;

                // Background Track
                .progress-track {
                    position: absolute;
                    top: 0.45rem;
                    width: 67%;
                    left: 16%;
                    background-color: $grey2;
                    background-color: #F1F1F1;
                    // z-index: -2;
                    height: 0.25rem;
                }
                
                // Each Step on the Progress Bar
                .progress-step {
                    position: relative;
                    top: -0.1rem;
                    width: 100%;
                    font-size: 12px;
                    text-align: center;
                  
                    // Hide the final step's progress bar
                    &:last-child:after {
                    display: none;
                    }
                    
                    // Step's circle in default state
                    &:before {
                    content: "";
                    display: flex;      
                    margin: 0 auto;
                    margin-bottom: 10px;
                    width: 10px;
                    height: 10px;
                    background: $white; 
                    border: 4px solid #F1F1F1;
                    border-radius: 100%;
                    color: transparent;
                    }
                    
                    // Step's progress bar in default state
                    &:after {
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 50%;
                    width: 0%;
                    // height: 1rem;
                    transition: width 1s ease-in;
                    height: 5px;
                    background: $grey2;
                    z-index: -1;
                    }

                    // Step's active state
                    &.is-active {
                    color: $blue;

                    &:before {
                        border: 4px solid #0D224B;
                        animation: pulse 2s infinite;
                    }
                    }
                    
                    // Step's complete state
                    &.is-complete {
                    color: $green;
                    
                    // Step's circle in complete state
                    &:before {
                        font-size: 10px;
                        color: $white;
                        background: #0D224B;
                        border: 4px solid transparent;
                    }
                    
                    // Step's progress bar in complete state
                    &:after {
                        background: #0D224B;
                        animation: nextStep 0s;
                        animation-fill-mode: forwards;
                    }
                    }

                    span {
                        font-weight: 600;
                        color: #0D224B !important;
                    }
                }
                }

                // Animations --------------------------------------
                // Pulse animation for Step's circle in active state
                @keyframes pulse {
                0% {
                    box-shadow: 0 0 0 0 rgba(33,131,221, 0.4);
                }
                70% {
                    box-shadow: 0 0 0 10px rgba(33,131,221, 0);
                }
                100% {
                    box-shadow: 0 0 0 0 rgba(33,131,221, 0);
                }
                }

                // Progressing to next step animation for Step's progress bar
                @keyframes nextStep {
                0% { width: 0%; }
                100% { width: 100%; }
                }

                // Button
                button {
                position: absolute;
                right: 50px;
                bottom: 20px;
                cursor: pointer;
                }
            }
        }
    }
}