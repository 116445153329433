.form {
    &.form-payoff-request {
        p {
            padding: 0 0 1rem 0 !important;
            color: red !important;
        }
    
        textarea {
            background-color: #f1f1f1;
            padding: 1rem;
            width: 100%;
        }
    
        .input-field {
            height: 6rem;
        }
    
        span.error-message {
            display: block;
            margin: 0.5rem 0 !important;
        }

        // .button-submit {
        //     margin: 1rem 0 0 0;
        // }
    }
}