@import "../../assets/css/_variables";

.top-bar {
  background-color: $light-grey;
  padding: 16px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 6fr;
  column-gap: o;

  @media (max-width: 1200px) { 
    padding: 30px 0;
  }

  @media (max-width: 800px) { 
    display: none;
  }

  .top-bar-left {
    padding: 0 0 0 40px;

    @media (max-width: 900px) { 
      padding: 0 0 0 1rem;
    }

    ul {
      li {
        display: inline-block;
        vertical-align: middle;
        padding-right: .5rem;
      }
    }

    svg {
      margin-top: .5rem;
    }

    input[type="text"] {
      width: 11.4rem;
      height: 2rem;
    }

    .account-search-bar {
      background-color: white;
      border: 1px solid #ccc!important;
    }

    .form-body {
      display: flex;

      input {
        padding: 1rem;
      }

      button {
        white-space: nowrap;
      }

    }

  }

  .top-bar-right {
    text-align: right;
    padding: 0 40px 0 0;

    @media (max-width: 900px) { 
      padding: 0 .5rem 0 0;
    }

    ul {
      li {
        display: inline-block;
        padding-right: .8rem;
        color: $darker-grey;

        &:last-child {
          padding-right: 0;
        }

        // .top-num {
        //   color: $orange;
        //   border: 2px solid $orange;
        //   padding: .3rem .5rem;
        //   border-radius: 100%;
        // }

        .searchbar-arrow {
          height: 0.5em;
          width: 1em;
          padding: 0.25em;
          position: relative;
          top: 0.25em;
          transition: 400ms;

          &.is-active {
            transform: rotate3d(1, 0, 0, 180deg)
          }
        }
      }
    }

 
    .nav-dropdown {
      height: 0;
      overflow: hidden;
      transition-duration: 300ms;
      transition-timing-function: ease;
      opacity: 0.1;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;

    a {
      display: block !important;
      padding: 0.25em 0;
     
    }

     &.is-active {
      pointer-events: all;
       height: 2em;
       opacity: 1;
     }
    }
  }
}