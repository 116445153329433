@import "../../../../../public/assets/css/variables";

.upload-files-table {
  width: 100%;

  tr {
    line-height: 2.5rem;
    vertical-align: middle;

    &:first-child {
      border-bottom: none !important;
    }

    &:nth-child(n + 1) {
      border-bottom: 1px solid $another-grey;
    }

    &:last-child {
      border-bottom: none !important;
    }

    td {
      padding-left: 1.5rem;
    }
  }
}

.file-upload {
  p {
    span {
      font-size: 1.3rem;
    }
  }

  p:nth-child(3) {
    color: #c9c9c9 !important;
  }

  img:first-child {
    height: 4rem !important;
    transition: all 0.3s ease;
  }

  .hovered {
    img:first-child {
      height: 5rem !important;
    }
  }

  fieldset {
    width: 100%;
    max-width: unset !important;
  }

  @media screen and (max-width: 600px) {
    .button-submit-rf {
      width: 100% !important;
    }
  }

  .heading {
    text-align: center;
    justify-content: center;
    border: 1.5px dashed $darker-grey;
    transform: translateY(-4rem);
    transition: all 0.5s ease;

    @media screen and (max-width: 600px) {
      width: 100%;

      .hide-mobile {
        display: none;
      }
    }

    div:first-child {
      padding: 1rem;
    }

    svg:first-child {
      font-size: 3rem;
      color: $dark_blue;
      left: 0.1rem;
      transition: all 0.5s ease;
    }

    p:nth-child(2) {
      margin-top: 1rem !important;
      color: $medium_blue;
    }

    p:nth-child(3) {
      margin-top: -1rem !important;
      color: $medium_blue;
    }

    .button-submit-rf {
      display: inline-block;
      margin: unset !important;
      margin-left: 1rem !important;
      margin-bottom: 1rem !important;
      font-size: unset !important;
      background-color: $blue_button !important;
      color: white !important;
      min-width: unset !important;

      .spinner {
        display: inline;
        padding: unset !important;
        svg {
          height: 0.9rem !important;
          filter: invert(100%) brightness(118%) contrast(119%);
        }
      }

      @media screen and (max-width: 600px) {
        margin: auto !important;
        margin-top: 1rem !important;
      }
    }

    button {
      margin-top: -1rem;
      background-color: rgba(0, 0, 0, 0);
      color: $blue_button;
      border: 1px solid $blue_button;
      padding: 1rem;
      transition: all 0.2s ease;
      min-width: 10rem !important;
      max-width: 13rem !important;
      height: 3rem !important;

      &:hover {
        color: white;
        background-color: $blue_button;
      }
    }
  }

  label {
    color: $darker-grey;
  }
}
