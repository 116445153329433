@import '../../../../../../../../css/variables.scss';
// @import "../../../../assets/css/forms.scss";

// reset style from library component
.ReactModal__Content.ReactModal__Content--after-open.construction-modal {
    padding: 0rem;
}

form.draw-request {
    position: relative;

   
    .btn-submit, .btn-submit.green {
        margin: 0rem 0rem 2rem 0rem !important;
    }

    p {
        margin: 0.5rem 1rem 0rem 1rem;
    }

    .modal-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 2rem;
        cursor: pointer;
    }

    #draw-modal-confirm {
        .input-field {
            padding: 0 !important;
        
        }
    }

    .submission-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 3rem 0 !important;

        * {
            margin-right: 2em;
        }

        .spinner-icon {
            display: none;

            &.is-active {
                display: block !important;
            }
        }

        .form-error {
            display: none;
            flex-basis: 100%;

            &.is-active {
                display: block;
            }
        }
    }

    .input-field-group.draw-modal {
        .input-field {
            &:nth-of-type(2) {
                label {
                    opacity: 0.5;
                }

                input {
                    pointer-events: none !important;
                    color: grey;
                }
            }
        }
    }

    .button-submit {
        margin-right: unset !important;

        .spinner, svg {
            margin-right: unset !important;
        }
    }
}

.request-draw {
    .modal {
        background: $modal-bg-white;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        width: 100%;
        height: 100%;
        align-items: flex-start;
        padding-top: 4rem;
    }

}

