@import '../../../../../assets/css/variables';


.terms-conditions-wrapper {
    .terms-conditions-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, 0.597);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: 0.1s ease;
        z-index: 999999;
    
        b {
            font-weight: bold;
        }

        &.is-active {
            opacity: 1;
            pointer-events: all;
        }

        .terms-inner {
            background-color: #fff;
            position: relative;
            max-width: 900px;
            max-height: 70%;
            width: 70%;
            overflow: scroll;
            padding: 4em 2em 3em 2em;
    
            @include tablet {
            width: 60%;
            height: auto;
        }

        .close {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            text-align: right;
           
            .terms-close_icon {
                color: black !important;
                font-size: 1.5em;
                padding: 1rem;
                margin: 1rem;
            }
        }

        .terms-inner_body {
        pointer-events: none;

            h1 {
                border-bottom: 2px solid #000000;
                padding-bottom: .9rem;
            }
            
            p {
                padding: .5rem 0;
                font-size: 0.8125rem;
            }
        }
    } 
    }
}