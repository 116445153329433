@import '../../../../../assets/css/variables';

.dashboard-customer {
    .layout_base {

    //    padding: 2rem !important ;
       
        @include tablet {
            border-top: 0rem;
        }

        @include desktop {
            border-top: 1rem !important;
        }
    }
   
    // section - top nav
    .top-nav {
        overflow: hidden;
        background: #0F2A5B;
        color: white;
        padding: 1rem 5rem;
        display: flex;

        .construction {
            opacity: 1;
            pointer-events: all;
            transition: 0.2s;
            font-weight: normal !important;

            &.is-inactive {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        a {
            padding: 0.5rem 3rem 0.5rem 0;
            color: white !important;
            position: relative;
            display: flex;
            justify-content: center;
            transition: 0.2s;
            

            &:after {
                transition: 0.2s;
                display: block;
                content: '';
                width: 1rem;
                height: 1rem;
                background: white;
                transform: rotate(45deg) translateY(1rem) translateX(1rem);
                position: absolute;
                top: 2.5rem;
                opacity: 0;
            }

            &:hover {
                color: white;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) translateY(0rem);
                }
            }

            &.is-active {
                text-shadow: 0.2px 0.2px white;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) translateY(0rem);
                }
            }
        }
    }

    // section - resources sub route
    .resources-customer {
        
        .section {
            padding: 4rem 2rem 6rem 2rem;
        
            * {
                line-height: 1.3;
            }
        
            @include desktop {
                padding: 4rem 4rem 6rem 4rem;
              }
        
            h1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $dark_blue;
                border-bottom: 2px solid $dark_blue;
            }

            .section_inner {
                h2 {
                    color: #0C224A;
                    font-size: 1.5rem;
                    margin-left: 0 !important;
                    font-weight: lighter;
        
                    @include tablet() {
                        // padding: 0 2rem;
                        padding-left: 0 !important;
                    }
                }

                p {
                    margin-left: 0 !important;
                }
        
                a {
                    text-decoration: underline;
                    color: #0C224A;

                    padding-left: 0;
        
                    @include desktop {
        
                    }
                }
        
                p {
                    font-style: unset !important;
                    max-width: 38rem;
                    margin-bottom: 0.5rem;
        
                    @include desktop {
                        margin-left: 2rem;
                    }
                }
        
                ul {
                    // background-color: red;
                    padding-top:0rem;
                    padding-bottom: 2rem !important;
                    
                    @include desktop {
                        margin-left: 0rem;
                     
                    }
        
                    li {
                        margin: 0.25rem 0;
                        display: flex;
                        align-items: center;

                        img {
                            height: 2rem !important;
                            margin-right: 1rem !important;
                        }
                    }
                }
        
                .marketing-materials {
                    display: flex;
                    flex-wrap: wrap;

                    .col {
                       flex-basis: 100%;
                       padding-bottom: 2rem;
                        margin: 2rem 0 2rem 0;
                       border-bottom: 1px solid lightgrey;

                        @include phone-med {
                            flex-basis: 33.33%;
                            text-align: center;
                            border-bottom: unset;
                            margin: 2rem 0 0 0;
                        }

                        img {
                            height: 4rem;
                            margin: 0 0 1rem 0;
                        }

                        ul {
                            margin-left: unset;
                        }
                    }
                }
        
                .additional-materials {
                    ul {
                       
                        @include phone-med {
                            margin-left: 2rem;
                        }
                    }
                }
            }
        
        
            
        }
    }
}
    