.rep-contact-info {
    span,a {
        line-height: 1.2;
    }

}

.thank-you-rf {
    p {
        margin-left: 0 !important;
    }
}

.app-new {
    padding: 0 0 !important;

    h1 {
        color: #0D224B !important;
    }

    p, a {
        color: initial !important;
    }
}