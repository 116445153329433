@import "../../assets/css/_variables";

.sign-up {
  padding: 4rem 5rem;

  .sign-up-title {
    color: $dark_blue;
    font-weight: bold;
    line-height: 1.8;
  }

  p {
    width: 80%;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    line-height: 1.2;
  }

  h1 {
    color: $dark_blue;
    border-bottom: 2px solid $dark_blue;
    padding-bottom: .5rem;
    margin-bottom: 1rem;

    @media (max-width: 425px) { 
      font-size: 1.375rem;
    }
  }

  

  .submit {
    background-color: $blue_button;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8125rem;
    padding: .5rem 1rem;
    margin-right: 1px;
    cursor: pointer;
  }

  .sign-up-inner {
    display: grid;
    grid-template-columns: 6fr 6fr;
  
    .sign-up-left, .sign-up-right {

      input {
        width: 92%;
        // background-color: $light-grey !important;
        // color: $darker-grey;
      
        @media (max-width: 1024px) { 
          width: 85%;
        }
      
        @media (max-width: 425px) { 
          width: 89%;
        }
      }
      
      select {
        // background-color: $light-grey;
        // color: $darker-grey;
        padding: 1rem;
        width: 97%;
        border: 0;
        -webkit-border-radius: 0px;
        border-radius: 0;
        height: 58px;
        margin-bottom: .5rem;
      
        @media (max-width: 425px) { 
          width: 98%;
        }
      }

      .selerror {
        border: 1px solid #EA7806 !important;
      }
    }
  }
}

.create-account {
  #signupform2 {
    label, input {
      color: white;
    }

    button {
      margin-left: 1rem;
    }
  }
}
