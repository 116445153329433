@import './variables.scss';

// Tables (base)
table.table {
    width: 100%;
    text-align: left;
    overflow: auto;
    margin-bottom: 0rem;
    border-right: 1px solid #F1F1F1;
    border-left: 1px solid #F1F1F1;
    border-bottom: 1px solid #F1F1F1;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: .25rem !important;
    position: relative;

    thead {
        
        tr {
            border-bottom: none;
            // border-bottom-left-radius: 0.25rem !important;
            // border-bottom-right-radius: 0.25rem !important;
            
            th {
                background: #F1F1F1;
               
                span {
                    padding: 0.75rem 1rem 0.5rem 1rem;
                    display: inline-block;
                    font-weight: bold !important;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                }
                
            }
        }
    }

    tbody {
        width: 100%;
 
        tr {
            border-bottom: 1px solid #F1F1F1;
            width: 100%;
            box-sizing: border-box;
            
            &:last-of-type {
                border-bottom: none !important;
            }

            // remove focus from interior links and place on whole row
            &:focus-within {
                outline: 3px solid #0091D0;
                outline-offset: -3px;
                z-index: 1;
                border-radius: 0.2em !important;

                td {
                    a {
                        text-decoration: underline;

                        &:focus {
                            outline: none !important;
                        }
                    }
                }
            }
        
          

            &.clickable {
                width: 100%;
                position: relative;
                cursor: pointer;

                th {
                    width: 100%;
                    // padding: 0.5rem 1rem;
                    background-color: #f1f1f134;
                  
                    &:focus-within {
                        outline: 3px solid #0091D0;
                        outline-offset: -3px;
                        z-index: 1;
                        border-radius: 0.2em !important;
                    }
    
                    &:hover {
                        color: #0091D0;
                    }

                    span {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    // hidden button
                    button {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                    }
                }
          
            }

            &:hover {
                background: #f1f1f12d;
                background: #63aad00e;
                
                a   { 
                    text-decoration: underline !important;
                }
            }
    
            td, th {
                span {
                    padding: 0.5rem 1rem !important;
                    font-weight: unset !important;
                    display: block;
                    // font-size: 0.8rem;
                }
    
                a {
                    text-decoration: none;
                    color: #0085CA;
                    padding: 0.5rem 1rem;
                    display: block;
                    // font-size: 0.8rem;

                    &:visited {
                        color: #0085CA !important;
                    }
                }
            }
        }
        
    }

    tbody.draw-table-body{
        .sublot-title {
            font-weight: bold;
            span:empty {
                padding: 0 !important;
            }
        }
        &:nth-of-type(even){
            background-color: #f1f1f1;
                border-color: #e1e1e1;
        
        }
        
        &:nth-of-type(even) tr,
        &:nth-of-type(even) tr td{
            border-color: #e1e1e1;
        }
    }

    // GLOBAL FONT SIZING
    td, th {
        span, a, button {
            font-size: 0.9rem !important;
            display: inline-block;

            @include tablet-lg() {
                font-size: 1rem !important;
            }
        }
    }
}