.input-radio-fields tr {
    flex-basis: 100%;
    width: 100%;
}

// .blue-bg {
//     padding-left: 1rem;
//     padding-right: 1rem;

//     .section.form-submit {
//         margin: auto;
//         margin-left: unset !important;
    
//         .bg-blue {
//             .input-field-group label {
//                 color: black !important;
//             }
//         }
//     }
// }

// .additional-guarantor-form {
//     .row {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         width: 100%;
//     }
      
//     .column {
//         display: flex;
//         flex-direction: column;
//         flex-basis: 50%;
//         padding: 1rem;
//         // order: 2;
//     }

//     textarea {
//         background-color: #f1f1f1;
//         padding: 1rem;
//         width: 100%;
//     }

//     .error-message {
//         color: #D4311E;
//     }
// }

// // .additional-guarantor-form.form.loc-borrower p {
// //     color: white !important;
// // }

// // .additional-guarantor-form.form.loc-borrower h1 {
// //     color: white !important;
// //     border-bottom: 2px solid white !important;
// // }

// // .additional-guarantor-form.form.loc-borrower h2 {
// //     color: white !important;
// //     border-bottom: 1px solid white !important;
// // }
// // .additional-guarantor-form.form.loc-borrower label {
// //     color: white !important;
// // }



// .layout_base.form-unprotected {
//     main.form.loc-borrower {
//         padding: 2rem !important;
//         color: white !important;
    
//         h1, h2 {
//             color: white !important;
//             border-bottom: 2px solid white !important;
//         }
    
//         p, label {
//             color: white !important;
//         }
//     }
// }

// fake commit

