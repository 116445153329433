@import '../../../../../../css/variables.scss';

#section-construction {
  h2 {
    justify-content: flex-start;
    span {
      color: black;
      font-weight: normal;
      font-size: 1rem;
      font-style: italic;
      display: none;
      margin-left: 1rem;

      @include tablet-lg() {
        display: inline-block;
      }
    }
  }
}

.construction-modal {
  width: 100% !important;
  max-width: 1000px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -400px;
  margin-left: -500px;  
  background-color: $white;
  padding: 2rem;
  outline: none;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .hide-on-mobile {
    display: none;
  }
  
}

  .construction-budget-items {
    padding-top: 1rem;

    table {
      width: 100%;
      font-size: 0.8125rem;
      text-align: left;

      ul {
        li {
          display: inline-block;
          padding-right: .5rem;
        }
      }

      thead {
        background-color: $light-grey;
        font-weight: 700;
        
        tr {
          th {
            padding: 1rem .5rem;
          }
        }
      }
    }
  }
// }

.construction-budget-items {
  padding-top: 0rem;

  .search-header-left {
    ul {
      li {
        display: inline-block;
        padding-right: 1rem;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  table {
    width: 100%;
    font-size: 0.8125rem;
    text-align: left;

    ul {
      li {
        display: inline-block;
        padding-right: .5rem;
      }
    }

    .item {
      width: 25%;
    }

    .desc {
      width: 26%;
    }

    .amount {
      width: 25%;
    }

    .balance {
      width: 12%;
      line-height: 1.4;
    }
  }

  .construction-budget-collapse {
    margin: 0 !important;

    table {
      width: 100%;

      thead {
        background-color: rgba(12, 34, 75, 0.11);

        tr {
          th {
            // padding: 1rem .5rem;
            padding: 0;
            // border: 1px solid $darker_grey;
          }
        }
      }

      tbody {
        tr {
          td {
            // padding: 1rem .5rem;
            padding: 0;
            // border: 1px solid $darker_grey;
          }
        }
      }
    }

    &.bottom-blue {
      margin-top: 1rem;

      .draw {
        background-color: $green_button;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.8125rem;
        padding: 1rem 1rem;
        margin-right: 1px;
      }
    

      table {
        thead {
          background-color: $dark_blue;
          color: $white;
        }

        tr {
          th {
            border: none;
            padding: 0;

            &.item {
              padding-left: 1rem;
            }

            &.balance {
              padding-left: 1rem;
            }

            &.budget {
              text-align: right;
            }
    
          }
        }

        tr {
          td {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
}

.submit-draw-modal-inner {
  .add-new-bank {
    padding-top: 2rem;

    input {
      background-color: $light-grey;
      color: $darker-grey;
      width: 60.5% !important;
    }

    select {
      background-color: $light-grey;
      color: $darker-grey;
      padding: .5rem;
      width: 60.5%;
      border: 0;
      -webkit-border-radius: 0px;
      border-radius: 0;
      height: 55px;
      margin-top: .2rem;
    }
  

    .search-header {
      display: grid;
      grid-template-columns: 8fr 4fr;
      border-bottom: 2px solid $dark_blue;
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;
  
      .search-header-right {
        text-align: right;
      }
    }
  }

  h1 {
    color: $dark_blue;
    border-bottom: 2px solid $dark_blue;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
  }

  h2 {
    color: $dark_blue;
    font-weight: 600;
    font-size: 1.75rem;
  }

  select {
    background-color: $light-grey;
    color: $darker-grey;
    padding: .5rem;
    width: 96.5%;
    border: 0;
    -webkit-border-radius: 0px;
    border-radius: 0;
    height: 55px;
    margin-top: .2rem;
  }

  .submit {
    background-color: $green_button;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8125rem;
    padding: 1rem;
    margin-right: 1px;
    margin-top: 1rem;
  }

  .cancel {
    background-color: $darker_grey;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8125rem;
    padding: 1rem;
  }

  .download {
    background-color: $blue_button;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8125rem;
    padding: 1.2rem 1rem;
    margin-right: 1px;
    margin-top: .3rem;
  }

  input {
    background-color: $light-grey;
    color: $darker-grey;
    width: 60.5%;
  }

  p {
    font-size: 0.8125rem;
  }

  .close-bar {
    text-align: right;
  }

  .add-member-body-mid {
    .new-bank {
      display: grid;
      grid-template-columns: 12fr;
      li {
        display: block;
      }
    }

    ul {
      display: grid;
      grid-template-columns: 8fr 4fr;
      li {
        display: inline-block;

        input {
          background-color: $light-grey;
          color: $darker-grey;
          width: 90%;

          &.smaller {
            width: 50%;
          }
        }

        select {
          width: 64%;
          height: 55px;
          margin-bottom: .5rem;
          margin-top: 0;

          &.bene {
            width: 96%;
          }
        }

        ul {
          display: grid;
          grid-template-columns: 2fr 4fr;
          li {
            text-align: left;

            select {
              width: 100%;
              height: 55px;
              margin-bottom: 0;
              margin-top: 0;
            }

            input {
              width: 39.5% !important;
              margin-left: .5rem;
            }
          }
        }
      }
    }

    hr {
      border: 1px solid $dark_blue;
      margin-bottom: 1rem;
    }
  }

  .add-member-body-bottom {
    p {
      padding: .5rem 0;
    }

    input[type="radio"] {
      float: left;
      margin-right: .5rem;
    }

    .bold {
      font-weight: bold;
    }
  }
}