@import "../../../../assets/css/variables";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;900&display=swap');

// RESET
nav {
  ul {
    margin-top: 0;
    li {
      &:hover {
        background: none;
      }
  }
}
}

// ===== ===== N A V  B A R ===== =====
.nav-bar {
  font-family: Montserrat;
  text-transform: uppercase;
  box-sizing: border-box;
  position: relative;
  background: white;
  flex-wrap: wrap;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center;
  overflow: visible;
  z-index: 1000;
  padding: 0;

  .nav-modal {
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -3;
    opacity: 0;
    transition-duration: 0.33s;
    transition-delay: 0.11s;
    pointer-events: none;
    
    &.is-active {
      opacity: 0.5;
      transition-delay: 0s;
    }
  }

  
  .logo {
    background: white;
    height: 6em;
    width: 100%;
    z-index: -2;
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    img {
      height: 4.5rem;
    }
  }

  .nav-links {
    box-sizing: border-box;
    display: block;
    width: 100%;
    background: white;
    z-index: -1;
    position: absolute;
    top: calc(-100vh + 6rem);
    transition-duration: 0.11s;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;
    border-radius: 0em;
    opacity: 0.5;
    height: calc(101vh - 6rem);
    overflow-y: scroll;
    
    &.is-active {
      transition-duration: 0.33s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: translateY(100vh);
      opacity: 1;
      transition-delay: 0.05s;
    }

    .nav-link-icon {
      display: none;
    }

    .nav-links-list-primary {
          margin-top: 0;
          padding: 0rem;
          margin: 2rem;
          padding-bottom: 6rem;
          box-shadow: 0 2px 5px rgba(0,0,0,.1);
          overflow-x: hidden;
          overflow-y: auto;
        }
 
        li {
          border: none;
          margin: 0;
          padding: 0;
            border-top: none;
            border-bottom: none !important;
            display: block;
            border-left: none !important;
            transition-duration: 0.2s;
            justify-content: flex-start;
            padding: 0em 0;   
            padding-right: 0;
            border-radius: 0.3rem;
          
          &:hover {
            background-color: none !important;
          }
        
          a, button {
            color: #06295E;
            text-transform: none;
            font-weight: 500 !important;
            font-family: Montserrat;
            cursor: pointer;
            height: unset;
            width: unset;
            border-radius: 0.15rem;
            transition: 0.11s;     
            padding: 0em 0;       
            border-bottom: 1px solid #F7F7F7;

            &:hover {
              background-color: #f7f7f7a1;
            }
          }


          &.other-link {
            
            a {
              color: #666666;
              padding: 1.5rem;
              display: block;
            }
          }

          &.has-children {
            .main {
              background-color: #F7F7F7;
              width: 100%;
              text-align: left;
              padding: 1.5rem;
              color: #666666;
              display: block;
            }

            .nav-links-list-secondary {
              background: white;
              border-radius: 0.3rem;
              // padding: 1rem;
              margin: 0.5rem 1rem 1rem 0.5rem;
              margin: 0;
              // padding: 1.5rem 0 1.5rem 4rem;
              // box-shadow: 0 2px 5px rgba(0,0,0,.1);
              
              ul.nav-link-secondary-main {
                margin: 0 0 0 4rem;

                li {  
                    a, button {
                      color: #666666;
                      padding: 0.25em 0;   
                      padding: 1rem 0 1rem 0rem;
                      display: block;
                    }
              }

              .nav-links-list-tertiary {
                  padding: 0 2rem;
                  
              }
            }
          }

          }
    }
  }

  // Toggle Icon
  .nav-toggle {
    width: 100%;
    background: white;
    cursor: pointer;
    height: 3em;
    border-bottom: 0.05em solid lightgrey;
    font-size: 2rem;
    color: #0A295E;

    display: flex;
align-items: center;
justify-content: space-between;

padding: 2rem 2rem 1rem 1rem;

.nav-toggle-logo {
  width: 14rem;
  // z-index: 1000;
  position: relative;
}
  }

  // ===========================================
  // ========== TABLET AND UP - BELOW ==========
  // ===========================================

  @include tablet-lg {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1rem 0;

    .logo {
      width: unset;
      padding-left: 4rem;
      position: relative;
      // top: -0.5rem;
      display: block;
    }
    
      .nav-links {
          width: unset;
          position: unset;
          display: flex;
          z-index: unset;
          height: unset;
          border-radius: unset;
          overflow: hidden;
          padding-right: 4rem;
          transition-duration: 0s;
          transition-delay: 0s;
          order: 1;
          opacity: 1;
          
          
        
        &.is-active {
          transition-duration: 0.33s;
          transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
          transform: translateY(100vh);
          opacity: 1;
          transition-delay: 0.11s;
          transition-duration: 0s;
          transition-delay: 0s;
        }
  
        .nav-link-icon {
          margin-left: 0.5rem;
          font-size: 0.8rem;
          display: inline-block;
        }
  
        .nav-links-list-primary {
            margin-top: 0;
              display: flex;
              justify-content: flex-end;
              flex-grow: 1;
              flex-wrap: wrap;
              width: unset;
              padding-bottom: 0rem;
              padding-top: 1rem;
              box-shadow: none;
            }
     
            li {
              border: none;
              margin: 0;
              padding: 0;
              border-top: none;
              border-bottom: none !important;
              display: block;
              border-left: none !important;
              transition-duration: 0.1s;
              justify-content: flex-start;
              padding-right: 0;
              padding: 0;
              border-bottom: none !important;
          
              &:hover {
                background-color: none !important;
              }
            
              a, button {
                color: #06295E !important;
                text-transform: uppercase;
                font-weight: 500 !important;
                font-family: Montserrat;
                cursor: pointer;
                height: unset;
                width: unset;
                border-radius: 0.3rem;        
                padding: 0.5rem 1rem !important;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: none !important;

                &.main {
                    background-color: white !important;
                    border-bottom: none !important;
                }

                &:hover {
                  color: #7BB6D9 !important;
                }

                &.get-started{
                  background: #7BB6D9;
                  border-radius: 3rem;
                  color: white !important;
                  

                  &:hover {
                    background: #005B9E;
                    color: white !important;
                  }
                }
              }

              &.has-children {
                .nav-links-list-secondary {
                  cursor: pointer;
                  display: block;
                  position: absolute;
                  opacity: 0;
                  transform: translateY(-1rem);
                  pointer-events: none;
                  transition-duration: 0s;
                  transition-delay: 0s;
                  padding: 0;
                  margin: unset;
                  background: none;
                  padding-top: 0rem;
                  border-left: 0.05em solid white;
                  border-right: 0.05em solid white;
                  border-bottom: 0.05em solid white;
                  box-shadow: 0 2px 5px rgba(0,0,0,.1);
                  
                  
                  ul.nav-link-secondary-main {
                    overflow: visible;
                    border-radius: 0.3rem;
                    border: 0.06rem solid white;
                    background: white;
                    padding: 1rem 1.5rem 1rem 0;
                    margin-left: 0 !important;
  
                    li {  
                      position: relative;
                      
                      &:hover {
  
                        a, button {
                          opacity: 0.66;
                          color: #7BB6D9 !important;
                        }
                      }
                    
                      a, button {
                        // color: white;
                        justify-content: flex-start;
                        color: #06295E;
                      }

                      &.has-children {
                        .nav-links-list-tertiary {
                          position: absolute;
                          top: -1em;
                          right: calc(-100% - 5.5rem);
                          padding: 0;
                          padding-left: 4rem;
                          opacity: 0;
                          pointer-events: none;
                          transform: translateX(-1rem);
                          
                          
                          .nav-link-tertiary-main {
                            margin-top: 0;
                            background: white;
                            padding: 1rem 0;
                            border: 0.06rem solid white;
                            border-radius: 0.3em;

                            li {
                              padding: 0 2rem;
                            
                              // position: relative;
                              left: -1rem;
                              a {
                                opacity: 1;

                                &:hover {
                                  opacity: 0.66 !important;
                                }
                              }
                            }
                          }
                        }

                        &:hover {
                          .nav-links-list-tertiary {
                            opacity: 1;
                            transition-duration: 0.22s;
                            transform: translateX(0rem);
                            pointer-events: all;
                          }
                        }
                      }
                  }
                }
              }
        
                &:hover .nav-links-list-secondary {
                  opacity: 1;
                  transform: translateY(0);
                  transition: 0.22s;
                  transition-delay: 0;
                  pointer-events: all;
                }
              } 
        }

       
      }
  
      .nav-toggle {
          display: none;
      }
    }
  }


  .nav-wrapper {
    background: white;
    position: relative;
    padding-top: 0rem;

    .auxillary-links {
      position: absolute;
      display: flex;
      width: 100%;
      // top: 1rem;
      right: 0;
      padding: 1rem 2rem;
      top: 0;
      justify-content: flex-end;
      // background: white;
      height: 1rem;
      z-index: 100000;

      @include tablet-lg() {
        padding: 1rem 6rem;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 1.5rem;

        svg {
          color: #225B99;
        }

        a {
          // background: #082C5C;
          color: #082C5C;
          padding: 0.5rem 0rem 0.5rem 0rem !important;
          margin: 0.25rem 0 0 0.5rem;
          display: block;
          font-weight: 500 !important;
          text-transform: none !important;
          font-weight: 500 !important;
          text-decoration: none;
        }
      }
    }

  
  }