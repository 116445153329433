@import "../../../../../../css/variables.scss";

.document-embed {
    .document-embed_iframe {
        margin: 0.5em 0;
    }
}

.file-preview-modal {
    position: absolute;
    width: 70vw;
    height: 90vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: calc(286px / 2);

    iframe {
        display: block; 
        width: 100%; 
        height: 100%; 
        border: none;
    }

    svg {
        color: white;
        font-size: 2rem;
        text-align: right;
        float: right;
        margin-bottom: 1rem;
        cursor: pointer;
    }
}

.filename {
    text-decoration: underline;
    color: $medium_blue;
    cursor: pointer;
}