@import '../../../../assets/css/variables';

.payoff-request {
    width: 100%;
    max-width: 1440px;
    margin: auto;

    @include tablet-lg {
        width: 80%;
    }

    h1 {
        color: #202E59;
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 700;
        border-bottom: unset;
        font-size: 5rem;
        margin: 2rem auto 2rem auto !important;
    }

    h1 {
        margin-left: 1rem !important;
    }

    .form.loc-borrower {
        margin-top: unset !important;

        p {
            color: black !important;
            // padding: unset !important;
        }

        .row {
            .column {
                flex-basis: 100%;

                @include tablet {
                    flex-basis: 50%;
                }
            }
        }

        .button-submit {
            width: 16rem;
            margin-top: 2rem;
            padding: 1.25rem 2rem;
            background-color: #182850;
            margin: 2rem auto 0 auto;
            border-radius: 2rem !important;

            @include tablet {
                margin: 2rem 0 0 0;
            }
        }
    }
}