.form {
    &.loc-borrower {
        .states-checkbox {
           &#loc {
            ul {
                height: 278px;
            }
           }
        }
    }
   
}

.form-error-b {
    margin-top: 1rem;

    a {
        color: red;
    }
}