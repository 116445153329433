@import '../../../../../../css/variables.scss';

#table-deal-members {
    tr {
        td, th {
            &:nth-of-type(3),
            &:nth-of-type(4) {
                display: none;

                @include tablet-lg() {
                    display: table-cell;
                }
            }
        }
    }
}