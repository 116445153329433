.Sandbox {
    padding: 2rem;

    h1, p, a {
        color: black;
        display: block;
        line-height: 1.2;
        margin-left: 0 !important;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}