.construction-subroute {

    .section {
        // margin: 1rem 0;

        .section_heading {
            // padding: 2rem 0 1rem 0;
            // border-bottom: 2px solid #0C224B;
            // display: flex;
            // align-items: center;
            // margin-bottom: 0.5rem;
      
            // h1 {
            //   color: #0C224B;
            //   margin-right: 1rem;
            //   margin-bottom: 0;
            // }

            // a {
            //     display: block;
            //     margin-top: 0.25rem;
            // }
          }
    }

    .construction-wrapper_inner {
    position: relative;
    // padding-bottom: 6rem;

        .loader  {
            position: absolute;
            top: 0;
            left: 0;
            background-image: linear-gradient(#ffffff, rgb(255, 255, 255));
            height: 100%;
            width: 100%;
            z-index: 1000;
            opacity: 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 6rem 0;

            &.is-hidden {
                opacity: 0;
                transition-delay: 0.2s;
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                pointer-events: none;
            }
        }
    }
}