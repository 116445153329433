@import "../../../../assets/css/_variables";


  .modal-continue {
    z-index: 999999;
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    padding: 4rem;
    background-color: rgba(0, 0, 0, 0.397);
    display: flex;
    justify-content: center;
    align-items: center;
  
    h1 {
      border-bottom: 2px solid #000000;
      padding-bottom: .9rem;
    }
  
    p {
      padding: 2rem 0;
    }

    .modal-continue_content {
      background-color: white;
      color: black;
      // font-weight: 700;
      font-size: 0.8125rem;
      padding: 2rem;
      text-decoration: none;
      max-width: 50rem;
      border-radius: 0.2rem;
      box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.315);

      @include tablet {
        padding: 4rem;
      }

      p {
        padding: 0.5rem 0;
        margin: 0.2rem 0 !important;
      }

      button {
        border-radius: 0.2rem;
        background-color: #C03939;
        color: white;
        padding: 1rem 2rem;
        text-transform: uppercase;
        font-weight: bold;
        margin: 1rem 0 0 0;
      }
    }
  }    

