@import '../../../../css/variables.scss';

#dashboard_loans-in-process {
    .section_content {
        padding-top: 0;

        .content-hidden {
            padding: 1rem 0;
        }
    }

  table.table {
         // scaling visible table cell data with increasing screen size
     th,td {
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(3),
        &:nth-of-type(6) {
            display: none;
        }

        @include tablet() {
            &:nth-of-type(6)
            {
               display: table-cell;
           }
        }

        @include tablet-lg() {
            &:nth-of-type(3)
            {
               display: table-cell;
           }
        }

        @include desktop() {
            &:nth-of-type(2),
            &:nth-of-type(4)
            {
               display: table-cell;
           }
        }
    }
  }
}