@import '../../assets/css/variables';

.spin {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.percentInput { position:relative; }
.percentInput span { position: absolute; right: 16px; top:12px; font-size: 24px }
.num_percent { padding-right: 12px; }

.fileError {
    color: #D4311E !important;

    input {
        color: #D4311E !important;
        background-color: #F7D8D3 !important;
    }
}

.input-field-group .input-field .radio-field {
    margin-top: 8px !important;
    li {
        overflow: visible;
        padding: 1rem 0.5rem !important;
        cursor: pointer !important;
    }

    input {
        width: 24px !important;
        height: 24px !important;
        cursor: pointer !important;
    }

    label {
        cursor: pointer !important;
        display: flex !important;
        align-items: center !important;
    }
}

.form {
    padding: 2rem 2rem;
    font-size: 14px;
   
    // base styles for elements
    * {
        position: relative;
        box-sizing: border-box !important;
        // border-radius: 0.1rem;

        // &:focus {
        //     outline: 0.15rem solid #0091d0;
        //     background-color: #f1f1f1;
        //     transition: 0.1s;
        // }

        
    }

    button {
        border: none;

        // &:focus {
        //     outline: unset !important;
        //     background-color: initial !important;
        //     transition: unset !important;
        // }
    }

    input, select {
        border: none;
        width: calc(100%);
        background-color: #f1f1f1;
        border: 0.15rem solid #f1f1f1;
        padding-left: 1rem;

         &:focus {
            outline: 0.15rem solid #0091d0;
            background-color: #f1f1f1;
            transition: 0.1s;
        }
    }

    input, input[type=text], input[type=number], input[type=date], select {
        height: 3.5rem !important;
    }

    label {
        display: block;
        margin: 0.5em 0;
    }

    h1 {
        color: #0c224b;
        border-bottom: 2px solid #0c224b;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
    }

    h2 {
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 1rem;
    }

    hr {
        height: 0.1rem;
        border: none;
        background-color: #d9d9d9c9;
        box-shadow: none;
        margin: 1rem !important;
    }

    p {
        font-size: 1em !important;
    }

    input {
        visibility: visible !important;
    }

    // form validation alert
    .form-alert {
        border: 0.15em solid #D4311E;
        background: #F7D8D3;
        color: #D4311E;
        display: none;
        margin: 2em 0;
        padding: 1rem;
        justify-content: flex-start;
        align-items: center;

        &.error {
            display: flex;
        }
        
        .alert-icon {
            font-size: 3rem;
            padding: 0 1rem 0 0;
            flex-basis: auto;
        }

        .alert-message {
            h2 {
                text-transform: uppercase;
                font-weight: 800;
                margin-bottom: 0.25em;
                padding-left: 0 !important;
            }
        }
    }

    .section {
        // margin: 2rem 0;
        padding:  0rem 0;

        .section_heading {
            padding: 1rem;

            p, h1, h2 {
                padding: 0;
            }

            h1 {
                text-transform: none;
                font-weight: 600;
                font-size: 1.75rem;
                padding-bottom: 0.5rem !important;
                color: #0c224b;
                border-bottom: 2px solid #0c224b;
            }
        }
    }

     // Main Body of Form
     .form-body {
         margin: 1rem 0;

        // base styles for inner elements
        .input-field-group {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end !important;
            // border: 0.15em dashed lightblue;
            padding: 0rem 0;

            .input-field-group_inner {
                flex-basis: calc(100%);
                display: flex;
                flex-wrap: wrap;

                @include tablet {
                    flex-basis: calc(50%);
                }
            }

            &.single-property-tf-qustions {
                display: flex;
        
                .input-field {
                    min-height: 11rem;
                    padding: 1rem 1rem !important;
               
    
                    border-top: 0.1rem solid lightgray;
                    border-right: 0.1rem solid lightgray;
                    border-left: 0.1rem solid lightgray;
    
                    &:last-of-type {
                        border-bottom: 0.1rem solid lightgray;
                    }

                    @include tablet {
                        border-top: 0.1rem solid lightgray;
                        border-right: 0.1rem solid lightgray;
    
                    &:nth-of-type(odd) {
                        border-left: 0.1rem solid lightgray;
                        border-right: none;
                    }

                    &:last-of-type {
                        border-right: 0.1rem solid lightgray;
                    }

                    &:nth-last-child(2) {
                        border-bottom: 0.1rem solid lightgray;
                    }
                    }
                   
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        list-style: none;
                    }
                }
            }

            .error-message {
                height: 3rem !important;
                max-height: 0;
            }

            &.form-errors {
                .error-message {
                    max-height: 2rem;
                }
            }

            &.error {
                background: white;
                outline: 0.15rem solid #D4311E;
            }
    
            .input-field {
                flex-basis: 100%;
                width: 100%;
                display: block;
                // border: 0.15em dashed lightpink;
                padding: 0rem 1rem;

                p {
                    // padding: unset !important;
                    font-weight: normal !important;
                }

                @include tablet {
                    flex-basis: calc(50%);

                    &:nth-of-type(odd) {
                        margin-right: 0em;
                    }
                }
    
                &.error {
                    label {
                        color: #D4311E !important;
                    }
    
                    input {
                        background: #F7D8D3;
                        outline: 0.15rem solid #D4311E;
                    }

                    select {
                        outline: 0.15rem solid #D4311E;
                        background: #F7D8D3;
                    }
    
                    * {
                        &:focus {
                            outline: 0.15rem solid #0091d0;
                        }
                    }

                    .error-message {
                        min-height: 2rem !important;
                    }
                }
    
                &.flex {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0 !important;

                    .input-field_inner {
                        flex-basis: 50%;
                        padding: 0 1rem;
                    }
                }

                &.full-width {
                    flex-basis: 100% !important;
                }
    
                .error-message {
                    color: #D4311E;
                    margin: 0.5em 0;
                    font-weight: bold;
                    display: block;
                    padding: 0em 0 !important;
                    opacity: 0;
                    transition: 0.1s;
                    height: 0rem;
                    overflow: hidden;
                    max-height: 0rem;
                    padding: 0 !important;
                    // border: 0.15em dashed green;
                }
    
                &.error {
                    .error-message {
                        opacity: 1;
                        max-height: 3rem;
                    }
                }

                ul {
                    // display: flex;

                    li {
                        display: flex !important;
                        align-items: center;
                        margin: 0.25rem 0.5rem 0.25rem 0;
                        width: max-content;
                        width: auto;

                        span, label, input {
                            display: inline-block;
                            margin-right: 0.5rem;
                        }

                        span {
                            position: relative;
                            top: 0.05rem;
                        }
                    }
                }
                }

                &.boxed {
                    border: 0.1rem solid #D9D9D9;
                    padding: 0;

                    .input-field {
                        border-top: 0.1rem solid #D9D9D9;
                        flex-basis: 100%;
                        padding-bottom: 0em;
                        border-bottom: none;
                        position: relative;
                        height: 100%;
        
                        &:first-child {
                            border-top: none;
                        }
        
                        .error-message {
                            opacity: 0;
                            display: block;
                            font-weight: bold;
                        }
        
                        &.error {
                            color: #D4311E;
                            // outline: 0.15rem solid #D4311E !important;
                            z-index: 1;
                            
        
                            label {
                                color: #D4311E;
                            }
            
                            input {
                                background: #F7D8D3;
                                color: #D4311E;
                            }
            
                            .error-message {
                                opacity: 1;
                            }
                        }
                    
                        @include tablet-med {
                            flex-basis: 50%;
        
                            &:nth-of-type(odd) {
                                border-right: 0.1rem solid #D9D9D9;;
                            }

                            &:nth-child(2) {
                                border-top: none;
                            }
            
                          
                        }
        
                        label {
                            padding: unset !important;
                            margin-bottom: 0;
                        }
        
                        ul {
                            display: flex;
        
                            li {
                                display: flex;
                            justify-content: flex-start;
                            align-items: center;
        
                                input[type="radio"] {
                                    width: 1em;
                                    height: 1em;
                                    padding: 1em;
                                    margin: 0 1em;
                                    border-radius: 100%;
                                    
                                    &:before {
                                    border-radius: 100%;
                                    }
                                }
                            }
                        &.error {
                            li {
                                input[type='radio'] {
                                // background: #F7D8D3;
                                outline: none;
                                border: 0.1rem solid #ffffff !important;
                                }
                            }
                        }
                        }
                    }
                
                }
            }

            .form-button {
                margin: 1rem 0;
                display: block;
                width: auto;
                max-width: max-content;
                font-size: 13px !important;
                border: none;
                background: #0086CA;
                color: white;
                font-weight: bold;
                text-transform: uppercase;
                padding: 1.25rem 2.25rem;
                border-radius: 0.1rem;
                cursor: pointer;
                transition: 0.1s;
                min-width: auto !important;
                display: flex;
                align-items: center;
                margin: 1rem 0 2rem 0;

                &:hover, &:focus {
                    background: #0086CA !important;
                }

                .form-button_icon {
                    margin-right: 0.5rem;
                    font-size: 1.25rem;
                }
            }

            p {
                padding: 1rem;
            }

            .spinner {
                display: none;

                &.is-active {
                    display: block;
                }
            }

            .form-error {
                display: none;
                font-weight: bold;
                color: #D4311E;
                
                h2 {
                    padding-left: 0rem !important;
                }

                &.is-active {
                    display: block;
                }
            }
        }

    p {
        color:  red !important;
        margin: 0 0 1rem 0 !important;
    }
}