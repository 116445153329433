@import '../../../../../assets/css/variables';

.footer-secondary {
    background: #0c224B;
    padding: 30px 0;

    .footer-secondary_inner {
        width: 80%;
        margin: auto;
        color: white;
        font-size: 16px !important;
        font-weight: 500;
        line-height: 1.2em;
        max-width: 1440px;
    
        a {
            color: white !important;
            font-weight: 500;
        }

        p {
            font-size: 1.25rem !important;
        }

        div {
            margin-top: 3rem;
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include tablet-lg {
                flex-direction: row;
            }

            p {
                margin: 30px 0 0 0 !important;
            }
        }
    }
}