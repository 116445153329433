// Summary - Access for unprotected legacy layouts with US-49 style updates (new version)

// Description - Wrapper for all of the general styles associated.  
// Imported into main style file as this is a strictly CSS variant of the parent styles


@import '../../css/variables.scss'; // global variables
@import './styles/layout_account-access.scss'; // account access views legacy styles with US-49 updates

.announcement-bar {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    font-size: 14px;
    box-shadow: #32325d1c 0 4px 6px, #00000014 0 1px 3px;
}

.fix-login {
    position: absolute;
    width: clamp(300px, 50vw, 1000px);
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5vw;
}

.layout_flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.layout_min {
    padding: 0;
    margin: 0;
}

.layout_generic {
    background-color: white;
    padding: 2rem;
    min-height: 80vh;

    @include desktop {
        padding: 2rem 4rem ;
    }

    &.grey {
        background-color: #F1F1F1;
    }

    .section-new {
        .mask {
            display: none;
        }
    }
}

// general layout
.layout_base {
    // height: 100%;
    padding: 3rem 2rem 3rem 2rem;
    min-height: calc(96vh - 2rem);
    border-bottom: 1rem solid #0c224B;
    max-width: 1200px;
    position: relative;
    width: 100%;
    background: white;
   

    @include tablet {
        border-right: 1rem solid #0c224B;
        border-top: 1rem solid #0c224B;

        .decor {
            background: #0c224B;
            height: 1rem;
            width: 1rem;
            position: absolute;

            .inner {
                height: 100%;
                width: 100%;
                background: white;
            }

            &.top-left {
                top: 0;
                left: 0;

                .inner {
                    border-top-left-radius: 0.25rem;
                }
            }

            &.top-right {
                top: 0;
                right: 0 !important;

                .inner {
                    border-top-right-radius: 0.25rem;
                }
            }

            &.bottom-right {
                bottom: 0;
                right: 0 !important;

                .inner {
                    border-bottom-right-radius: 0.25rem;
                }
            }

            &.bottom-left {
                bottom: 0;
                left: 0;

                .inner {
                    border-bottom-left-radius: 0.25rem;
                }
            } 
        }
    }

    @include desktop {
        padding: 3rem 4rem 3rem 4rem ;
    }
}


// unstyled whitelabel applications
.layout_white-label-applications {
    background-color: white;
    padding: 0 2rem;

    @media screen and (max-width: 750px) {
        padding: unset !important;

        .inner {
            padding: 2rem 0 !important;
            width: 90%;
        }

        .row .column {
            width: 100% !important;
        }

    }
    
    * {
        box-sizing: border-box;
    }

    .inner {
        max-width: 1200px;
        width: 80%;
        margin: auto;
        padding: 6rem 0;
    }

    .form {
      .form-body {
        color: black;

        p {
            margin: 0 !important;
            padding: 0rem !important;
            color: black !important;
        }

        .section {
            h1 {
                color: #1C3144 !important;
                border-color: black !important;
            }  

            h2 {
                margin: 2rem 0 1rem 0 !important;
            }

            .row {
                flex-direction: column;

                @include tablet() {
                    flex-direction: row;
                }
            }
            .section_heading {
                padding: 0;
            }

            hr {
                background-color: black;
            }

            .input-field-group {
                    fieldset.requested-loan-info  {
                        border: none !important;
                    }
            }

            .input-field-group_inner.states-checkbox {
                .input-field {
                    border-color: #D9D9D9;
                }

              
            }

            .hidden-input {
                margin: 1rem 0 2rem 0;

                .input-field {
                    border: none !important;
                }
            }
        }

        .checkbox-list {

            .input-field {
                margin-bottom: 1rem !important;
                height: 233px;
                overflow-y: hidden;
                border: none !important;
    
                label {
                    padding: 0.25rem 0rem !important;
                }
    
                ul {
                    height: 230px !important;
                    // border: 0.1rem solid #F1F1F1;
                    display: block;
    
                    li {
                        margin-right: 0 !important;
                        margin-left: 0 !important;
                        border-bottom: 1px solid #F1F1F1;
                        height: unset !important;
                   
    
                        &:last-child {
                            border-bottom: none !important;
                        }
    
                        &:focus-within {
                            outline: 3px solid #0091D0 !important;
                            outline-offset: -3px;
                            border-radius: 0.2em;
                        }
                        
                        label {
                            padding: 0.4rem 1rem !important;
                            display: flex;
                            align-items: center;
                            height: unset !important;
                            cursor: pointer;
    
                           input {
                               height: unset !important;
                               &:focus {
                                   outline: none !important;
                               }
                           }
                            
                        }
                    }
                }
            }
        }
      }
    }
}
