@import '../../../../assets/css/variables';

.signup-rf {
    display: block;
    // max-width: 1440px;
    margin: auto;
    position: relative;
    width: 100%;

    h3 a {
        color: $lighter__blue !important;
        font-size: inherit !important;
    }

    @include tablet-lg {
        display: flex;
        justify-content: center;
    }

    .signup-rf_panel {
       padding: 4rem 2rem;

       @include tablet-lg {
        min-height: 80vh;
        padding: unset;
       }

       h2 {
           font-weight: bold;
           text-align: center;
       }

       h3 {
           width: 100%;
           text-align: center;
           font-weight: bold;
           font-size: 2rem;
           margin: 3rem 0rem 2rem 0rem !important;
       }

        &.one {
            flex-basis: 40%;
            background-image: url(/assets/images/lead-big.jpg);
            background-position-y: top;
            background-position-x: right;
            background-repeat: no-repeat;
            

            @include tablet-lg {
                background-position-y: 0rem;
                background-size: cover;
                display: flex;
                justify-content: flex-end;
            }

            .one_inner {
                // border: 1px solid red;
              
                @include tablet-lg {
                    // max-width: calc(440px);
                    padding: 6rem 4rem 4rem 0;
                    margin-left: 25%;
                }
            }

            p {
                color: #202E59 !important;
            }
        }

        &.two {
            flex-basis: 60%;
           
            @include tablet-lg {
                // height: 90vh;
                // overflow: auto;
            }

            .two_inner {
                // border: 1px solid red;
                

                @include tablet-lg {
                    // max-width: calc(1000px);
                    // padding: 6rem 0 4rem 4rem;
                    padding: 6rem 0rem 4rem 4rem;
                    margin-right: 16.75%;
                }
            }

            p {
                text-align: center;
            }

            .step-wrapper {
                
                h2 {
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 1.25rem;
                }
                

            .steps {
                display: flex;
                margin: auto;
                justify-content: space-between;
                align-items: center;
                width: 20em;
                position: relative;
                

                button.step {
                    margin: 0;
                    background: none;
                    padding: 1rem;
                    z-index: 1;
                    pointer-events: none;

                    &:focus {
                        outline: 2px solid #0091d0 !important;
                    }

                    .step_inner {
                        width: 1rem;
                        height: 1rem;
                        background: #EFEFEF;
                        border: 1px solid #1C2446;
                        border-radius: 1rem;
                        pointer-events: none;
                    }
                   

                    &.is-active {
                     
                        pointer-events: all;
                        cursor: pointer;

                        .step_inner {
                            background: #1C2446;
                        }

                        &:hover {
                            .step_inner {
                                background: #68AED2;
                                border-color: #68AED2;
                                transform: scale(1.25);
                            }
                           
                        }
                    }
                }

                .step-line {
                    position: absolute;
                    background: #1C2446;
                    height: 2px;
                    margin: auto;
                    width: calc(100% - 3rem);
                    left: 1.5rem;
                    top: 1.4rem;
                }
            }

            form {
                max-width: unset !important;
            }

            .form-panel {
                display: none;
                width: 100%;

                &.is-active {
                    display: block;
                }

                .sign-up-inner {
                  padding: 1rem 0 2rem 0;

                  li {
                      font-size: 1.25rem;
                  }
                    
                    .input-field {
                        input {
                            padding: 0.25rem;
                            font-size: 1.25rem;
                        }
                        input:focus {
                                outline: 2px solid #0091d0 !important;
                        }

                        label {
                            min-height: 4rem;
                            font-size: 1.25rem;
                            line-height: 1.2;
                        }

                        select {
                            width: 100%;
                            border: 1px solid #3B4668;
                            padding: 0.5rem;
                            color: black !important;
                            border-radius: 0.25rem;
                            font-size: 1.25rem;
                        }

                        ul {
                            display: flex;
                            margin-bottom: 1rem;

                            li {
                                display: flex;
                                margin: 0rem 1rem 1rem 0;

                                @include tablet-lg {
                                    margin: 1rem 1rem 1rem 0;
                                }
                                
                                input[type=radio] {
                                    margin: 0 1rem 0 0 !important;
                                }
                              }
                        }
                  
                    }

                  @include tablet {
                    display: flex;
                    flex-wrap: wrap;

                    .input-field {
                        flex-basis: calc(50% - 2rem);
                        margin: 1rem;
                    }
                  }
                }

                .input-field.error {
                    label {
                        color: red !important;
                    }

                    .error-message {
                        display: none !important;
                    }
                }

                .input-field-ao {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    max-width: 34rem;
                    margin: 3rem auto 3rem auto;

                    @include tablet-lg {
                        max-width: 36rem;
                    }
                    
                    .input-ao {
                        // flex-basis: calc(100% - 2rem);
                        border-radius: 0.25rem;
                        border: 0.25rem solid white;
                        margin: unset;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 0.5rem;
                        background: white;
                        text-align: center;
                        width: 11rem;
                        height: 10rem;
                        font-weight: bold;
                        position: relative;
                        // padding-top: 1rem;

                        @include tablet-lg {
                            height: 12rem;
                            width: 13rem;
                        }

                        &:hover {
                            box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.363);
                        }

                        &:focus {
                            outline: none; 
                            border-color: #0091d0 !important;
                            box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.363);
                        }
               

                        &.is-active {
                            border-color: #1B294D;;
                            background: #1B294D;
                            color: white;
                            box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.363);
                        }

                        img {
                            height: 5rem;
                            pointer-events: none;

                            @include tablet-lg {
                                height: 6rem;
                            }
                        }

                        p {
                            margin: 1rem 0 0.5rem 0;
                            pointer-events: none;
                            font-size: 1.15rem !important;

                            @include tablet-lg {
                                bottom: 0.5rem;
                                font-size: 1.25rem !important;
                            }
                        }
                    }

                    button:nth-of-type(1) {
                        margin: 0 0.5rem 0.5rem 0;

                        img {
                            transform: scale(1.5);
                        }
                    }

                    button:nth-of-type(2) {
                        margin: 0 0 0.5rem 0.5rem;

                        img {
                            transform: scale(1.25);
                        }
                    }

                    button:nth-of-type(3) {
                        margin: 0.5rem 0.5rem 0 0;
                    }

                    button:nth-of-type(4) {
                        margin: 0.5rem 0 0 0.5rem;
                    }
                }

                .agree-section {
                    width: 100%;

                    p {
                        text-align: left;
                        margin: 1rem 0 2rem 0 !important;
                    }

                    @include tablet {
                        padding: 0 1rem;
                    }

                    .agree-section-body {
                        display: flex;
                        align-items: center;
                        font-size: 1.25rem !important;
    
                        input {
                            margin: 0 1rem 0 0;
                            margin-top: unset !important;
                            height: unset;
                            width: unset;
                            

                           
                        }
                        &.error {
                            color: red !important;
                        }

                        input:focus {
                            outline: 2px solid #0091d0 !important;
                        }
                    }

                    .error-message {
                        color: red;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    }

    .inperror {
        border: 1px solid red !important;
    }

    .guide {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1000;
        pointer-events: none;

        .guide-inner {
            box-sizing: border-box;
            margin: auto;
            width: 80%;
            height: 100%;
            // border: 1px solid grey;
        }
    }
}